import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, useTheme } from '@mui/material';

import { Formik } from "formik";
import ComboBoxFilled from "../globals/comboBoxFilled";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


const SearchFilters = ({ data, onSubmit }) => {
	useEffect(() => {
		const savedFilters = localStorage.getItem("searchFilters");
		if (savedFilters) {
			const parsedFilters = JSON.parse(savedFilters);
			setInitialValues(parsedFilters);
		}
	}, []);

	const handleFormSubmit = (values) => {
		localStorage.setItem("searchFilters", JSON.stringify(values));
		onSubmit(values);
	};

	const [initialValues, setInitialValues] = React.useState(() =>
		data.reduce((acc, field) => {
			if (field && field.name) {
				acc[field.name] = "";
			}
			return acc;
		}, {})
	);

	const handleResetForm = (resetForm) => {
		localStorage.removeItem("searchFilters");
		resetForm({
			values: {},
		});
	};

	return (
		<Box
			sx={{
				width: "100%",
				backgroundColor: "#f1f2f3",
				padding: "10px",
				borderRadius: "10px",
			}}
		>
			<Formik
				onSubmit={handleFormSubmit}
				initialValues={initialValues}
				enableReinitialize
			>
				{({
					values,
					errors,
					touched,
					handleBlur,
					handleChange,
					handleSubmit,
					resetForm,
					setFieldValue,
				}) => (
					<form onSubmit={handleSubmit}>
						<Box
							sx={{
								width: "100%",
								margin: "0 auto",
								borderRadius: "15px",
								display: "grid",
								gap: "5px",
								gridTemplateColumns: "repeat(7, minmax(0, 1fr))",
							}}
						>
							{data.map((field, index) => {
								if (!field || !field.name) return null;

								const commonProps = {
									key: index,
									sx: { gridColumn: field.gridColumn },
								};

								switch (field.formType) {
									case "comboBox":
										return (
											<ComboBoxFilled
												{...commonProps}
												data={field}
												index={index}
												value={values[field.name] || []}
												onChange={(event) =>
													setFieldValue(field.name, event.target.value)
												}
											/>
										);

									case "date":
										return (
											<div key={index}>
												<b style={{ marginTop: "20px", display: "block" }}>
													{field.label}
												</b>
												<TextField
													fullWidth
													variant="filled"
													type="date"
													label={field.label}
													InputLabelProps={{ shrink: true }}
													name={field.name}
													value={values[field.name] || ""}
													onChange={handleChange}
													onBlur={handleBlur}
													error={!!touched[field.name] && !!errors[field.name]}
													helperText={touched[field.name] && errors[field.name]}
													disabled={field.disabled}
													{...commonProps}
												/>
											</div>
										);

									case "text":
									default:
										return (
											<div key={index}>
												<b style={{ marginTop: "20px", display: "block" }}>
													{field.label}
												</b>
												<TextField
													fullWidth
													variant="filled"
													type="text"
													label={field.label}
													name={field.name}
													value={values[field.name] || ""}
													onChange={handleChange}
													onBlur={handleBlur}
													error={!!touched[field.name] && !!errors[field.name]}
													helperText={touched[field.name] && errors[field.name]}
													disabled={field.disabled}
													{...commonProps}
												/>
											</div>
										);
								}
							})}
						</Box>

						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								marginTop: "20px",
							}}
						>
							<Button
								type="submit"
								color={data.colorBtn}
								variant="contained"
								sx={{ marginRight: "5px" }}
							>
								Buscar
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export default SearchFilters;