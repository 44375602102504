
export const projectList = [
	{
		"field": "nombre",
		"headerName": "Nombre del Proyecto",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "descripcion",
		"headerName": "Descripción",
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "total",
		"headerName": "Costo Total",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"flex": 1
	},
	{
		"field": "fechaCreacion",
		"headerName": "Fecha de Creación",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	},
	{
		"field": "fechaEdicion",
		"headerName": "Fecha de Edición",
		"headerAlign": "center",
		"align": "center",
		"currency": 1,
		"width": 100
	}
];

export const proyectDetails = [
	{
		"field": "nombreArt",
		"headerName": "Nombre",
		"headerAlign": "center",
		"detalle": "",
		"align": "center",
		"width": 100
	},
	{
		"field": "nombre",
		"headerName": "Categoría / Clave / SKU",
		"headerAlign": "center",
		"align": "center",
		"width": 400
	},
	{
		"field": "precioCompra",
		"headerName": "Precio de Compra",
		"headerAlign": "center",
		"align": "right",
		"currency": 1,
		"width": 100
	},
	{
		"field": "elementos",
		"headerName": "Elementos",
		"headerAlign": "center",
		"detalle": "",
		"align": "center",
		"width": 10
	},
	{
		"field": "unidad",
		"headerName": "Unidad",
		"headerAlign": "center",
		"detalle": "",
		"align": "center",
		"width": 100
	},
	{
		"field": "precioVenta",
		"headerName": "Precio de Venta",
		"headerAlign": "center",
		"align": "right",
		"currency": 1,
		"width": 50
	},
	{
		"field": "acciones",
		"headerName": "Acciones",
		"flex": 1,
		"headerAlign": "center",
		"align": "center",
		"width": 100
	}
];
