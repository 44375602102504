import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Box, Typography } from "@mui/material";

const FileUploader = ({ setFieldValue, value, params }) => {
    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            console.log("📸 Archivo seleccionado:", file);
            setFieldValue("image", file);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "image/jpeg": [],
            "image/png": [],
            "application/pdf": []
        },
        multiple: false,
    });

    return (
        <Box
            {...getRootProps()}
            sx={{
                width: params.width, 
                height: params.height,
                border: "2px dashed gray",
                borderRadius: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                cursor: "pointer",
                backgroundColor: "#f9f9f9",
                overflow: "hidden",
            }}
        >
            <input {...getInputProps()} />
            {value ? (
                <img
                    src={typeof value === "string" ? value : URL.createObjectURL(value)}
                    alt="Preview"
                    style={{
                        width: "100%", 
                        height: "100%",
                        objectFit: "contain", 
                        borderRadius: "10px",
                    }}
                />
            ) : (
                <Typography variant="body1" color="textSecondary">
                    Arrastra y suelta una imagen aquí o haz clic para seleccionar una.
                </Typography>
            )}
        </Box>
    );
};

export default FileUploader;