import { Grid } from "@mui/material";

import calculatePrecioVenta from "../globals/sellPrice";

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

const ArticleDetails = ({ data, initValues, porcentajeGlobal }) => {
    if (!initValues) return null;
    const { precioVentaValor, isPrecioVentaMenor } = calculatePrecioVenta(
        initValues.precioCompra,
        initValues.precioVenta,
        porcentajeGlobal
    );

    return (
        <>
            <Grid item xs={8} md={initValues.tipo === "Compuesto" ? 2 : 3}>
                <img
                    src={`/assets/articulos/${initValues.imagen && initValues.imagen.trim() !== "" ? initValues.imagen : "art.jpg"}`}
                    width="100%"
                    style={{ bottom: "-1px", position: "relative" }}
                    alt="Imagen del artículo"
                />
            </Grid>
            
            <Grid item xs={8} md={["articles", "projects"].includes(data.module) ? 4 : (initValues.tipo === "Compuesto" ? 4 : 9)}>
                <ul>
                    <li><strong>Nombre:</strong> {initValues.nombreArt}</li>
                    <li><strong>Clave / SKU:</strong> {initValues.clave}</li>
                    <li><strong>Código de Barras:</strong> {initValues.codigoBarras}</li>
                    <li><strong>Precio de Compra: </strong> {formatCurrency(initValues.precioCompra)}</li>
                    <li>
                        <strong>Precio de Venta: </strong>
                        <span
                            style={{
                                color: precioVentaValor < initValues.precioCompra ? "red" : "inherit",
                                fontStyle: precioVentaValor < initValues.precioCompra ? "italic" : "normal"
                            }}
                        >
                            {formatCurrency(precioVentaValor)}
                        </span>
                    </li>
                    <li><strong>Tipo:</strong> {initValues.tipo}</li>
                    <li><strong>Familia:</strong> {initValues.familia}</li>
                    <li><strong>Cuenta:</strong> {initValues.cuenta}</li>
                    <li><strong>Tipo de Unidad:</strong> por {initValues.unidad}</li>
                    <li style={{ width: "100%", marginBottom: "35px" }}>
                        <strong>Descripción del Servicio:</strong><br />
                        {initValues.descLarga}
                    </li>
                </ul>
            </Grid>
        </>
    );
};

export default ArticleDetails;
