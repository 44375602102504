import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Box, useTheme, Grid, Typography, Paper, Modal, Button } from '@mui/material';

import { tokens } from "../../data/tokens";

import { getPostData, getDataArray } from '../../system/getData';
import { CreateSection, CreateProject } from "./addElements";

import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

import { SimpleDataTable, ExpandableDataTable } from "../../components/globals/newDataTable";

export const ProjectsList = ({ data }) => {
    const navigate = useNavigate();

    const [rows, setRows] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(true);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const { config, configData } = UseAppConfig();

    const fetchDataTable = async () => {
        try {
            setLoading(true);
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`);
            const formatData = jsonData.map(row => ({
                ...row,
                status: row.status == 1 ? 'Activo' : 'Desactivado'
            }));
            setRows(formatData);
            console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
            setRows([]);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchDataTable();
    }, [data.endpoint]);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    const handleModalOpen = (section) => {
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    return (
        <Box m="20px">
            <Box
                key="Agregar Nuevo"
                style={{ position: 'absolute', top: '50px', right: '60px', zIndex: 100 }}
            >
                <Button style={{ marginLeft: '10px' }} variant="contained" color="info" onClick={() => handleModalOpen('projects')} >
                    Agregar Nuevo Proyecto
                </Button>
            </Box>
            <SimpleDataTable data={data} />
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '1000px',
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}
                >
                    <CreateProject data={''} />
                </Paper>
            </Modal>
        </Box>
    );
};

export const ProjectDetails = ({ data }) => {
    const [loading, setLoading] = useState(true);

    const [projData, setProjData] = useState(null);
    const [catID, setCatID] = useState(null);

    const navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const { config, configData } = UseAppConfig();
    const [refresh, setRefresh] = useState(false);

    const formatFechaEdicion = (fechaString) => {
        const fecha = new Date(fechaString);
        const opcionesFecha = { year: 'numeric', month: '2-digit', day: '2-digit' };
        const opcionesHora = { hour: '2-digit', minute: '2-digit' };
        const fechaFormateada = fecha.toLocaleDateString('es-MX', opcionesFecha);
        const horaFormateada = fecha.toLocaleTimeString('es-MX', opcionesHora);

        return `${fechaFormateada} ${horaFormateada}`;
    };

    const fetchData = async () => {
        try {
            const apiData = await getDataArray(`${config.API_BASE_URL}/${data.getData}`, { id: data.id });
            setProjData(apiData.projectDetails[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [data.id, data.getData, refresh]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    const handleModalOpen = (id, section) => {
        setModalSection(section);
        setCatID(id);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const ProjectDescription = () => {
        return (
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h2" align="left" gutterBottom>
                                {projData.nombre}
                            </Typography>
                            <Box display="flex" gap={2} alignItems="center">
                                <Button
                                    variant="contained"
                                    color="warning"
                                    style={{ minWidth: '25px' }}
                                    onClick={() => handleModalOpen(projData.id, 'editProyect')}
                                >
                                    <FontAwesomeIcon icon={faEdit} />&nbsp;Editar
                                </Button>
                                <Button variant="contained" onClick={handleBack} color="success" autoFocus>
                                    Regresar
                                </Button>
                            </Box>
                        </Box>
                        <hr noshade="-1"></hr>
                        <Typography variant="h4" gutterBottom>
                            Descripción: {projData.descripcion}
                            <br/><br/>
                        </Typography>
                        <Typography variant="h6" gutterBottom>
                            Porcentaje de Utilidad: <b>{projData.porcentajeUtilidad}%</b><br/>
                            <small>Se Aplicará automáticamente para cada artículo agregado al Proyecto</small>
                            <hr nosshade="-1"></hr>
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={8} md={2}>
                                <Typography variant="body1" paragraph>
                                    Fecha de Creación:<br />
                                    {formatFechaEdicion(projData.fechaCreacion)}
                                </Typography>
                            </Grid>
                            <Grid item xs={8} md={2}>
                                <Typography variant="body1" paragraph>
                                    Última Fecha de Edición:<br />
                                    {formatFechaEdicion(projData.fechaEdicion)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <br />
                        <hr style={{ border: '1px solid #ccc', marginTop: '10px' }} />
                    </Grid>
                </Grid>
            </Box>
        );
    };

    const DisplayDetails = ({ data, projData }) => {
        return (
            <Box>
                <Box>
                    <ExpandableDataTable data={data} projData={projData} />
                </Box>
            </Box>
        );
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'editProyect':
                return <CreateProject data={projData} />;
            default:
                return null;
        }
    };

    return (
        <Grid container justifyContent="center" className="row-printable">
            <Grid item xs={12} md={12}>
                <Paper className="panel">
                    <Box p="0px">
                        <main>
                            <ProjectDescription />
                            <Box>
                                <DisplayDetails data={data} projData={projData} />
                            </Box>
                        </main>
                    </Box>
                </Paper>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            width: 1000,
                            padding: 3,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2
                        }}
                    >
                        {renderModalContent()}
                    </Paper>
                </Modal>
            </Grid>
        </Grid>
    );
};