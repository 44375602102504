import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import CloseIcon from "@mui/icons-material/Close";
import { Box, TextField, Container, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme, TablePagination, Pagination, Alert, IconButton } from '@mui/material';

import Papa from "papaparse";
import { Formik } from "formik";
import { useSnackbar } from 'notistack';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { tokens } from "../../data/tokens";
import { useNavigate } from 'react-router-dom';
import { sendPostFileData, sendPostData } from '../../system/getData';

import { GetCompoundAmounts, CompoundDetails } from "../compounds/compoundList";

import FileUploader from "./fileUploader";
import ComboBoxFilled from "../globals/comboBoxFilled";
import ArticleDetails from "../globals/articleCard";

import { UseAppConfig } from '../../system/config';
import { Header } from "../basics/Header";

const formatCurrency = (value) => {
	return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

export const GeneraFormularioSimple = ({ data, initValues, reloadData, setModalOpen }) => {
	const navigate = useNavigate();
	const { enqueueSnackbar } = useSnackbar();
	const { config, configData } = UseAppConfig();

	const handleFormSubmit = async (values) => {
		const formData = new FormData();
		if (values.image instanceof File) {
			console.log("✅ Archivo válido:", values.image);
			formData.append("image", values.image);
		} else {
			console.error("❌ La imagen no es un archivo válido", values.image);
		}

		Object.keys(values).forEach((key) => {
			if (key !== "image") {
				formData.append(key, values[key]);
			}
		});

		console.log("📡 Enviando FormData...");

		for (let pair of formData.entries()) {
			console.log(pair[0], pair[1]);
		}

		try {
			const result = await sendPostFileData(`${config.API_BASE_URL}/${data.sendData}`, formData);
			enqueueSnackbar(data.msgSuccess, { variant: "success" });

			if (reloadData) {
				reloadData();
			} else {
				navigate(0);
			}
			if (setModalOpen) {
				setModalOpen(false);
			}
		} catch (error) {
			console.error("❌ Error al subir el archivo:", error);
			enqueueSnackbar("El formato del archivo seleccionado es incorrecto.", { variant: "error" });
		}
	};

	const uploadParams = {
		width: '100%',
		height: '300px'
	};

	return (
		<Box sx={{ width: "100%", borderRadius: "10px", padding: "20px" }}>
			<Formik initialValues={initValues} onSubmit={handleFormSubmit}>
				{({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
					<form onSubmit={handleSubmit}>
						<Grid container spacing={2} alignItems="flex-start">
							<Grid item xs={4}>
								<FileUploader
									setFieldValue={setFieldValue}
									value={values.image}
									params={uploadParams}
								/>
							</Grid>

							<Grid item xs={8}>
								<Grid container spacing={2}>
									{data.key.map((field, index) => {
										const isTextField = field.formType === "text";
										const isTextArea = field.formType === "textArea";
										const isComboBox = field.formType === "comboBox";
										const isFullWidth = field.name === "nombreArt"; // 🔥 Nueva condición

										return isTextArea ? (
											<Grid item xs={12} key={index}>
												<b style={{ marginTop: "20px", display: "block" }}>{field.label}</b>
												<TextField
													fullWidth
													variant="filled"
													multiline
													rows={4}
													onBlur={handleBlur}
													onChange={handleChange}
													value={values[field.name]}
													name={field.name}
													disabled={field.disabled}
													error={!!touched[field.name] && !!errors[field.name]}
													helperText={touched[field.name] && errors[field.name]}
												/>
											</Grid>
										) : (
											<Grid item xs={isFullWidth ? 12 : 4} key={index}> {/* 🔥 Aquí aplicamos la lógica */}
												{isComboBox ? (
													<ComboBoxFilled
														key={index}
														data={field}
														index={index}
														value={values[field.name]}
														onChange={(event) => setFieldValue(field.name, event.target.value)}
													/>
												) : isTextField ? (
													<>
														<b style={{ marginTop: "20px", display: "block" }}>{field.label}</b>
														<TextField
															fullWidth
															variant="filled"
															type="text"
															onBlur={handleBlur}
															onChange={handleChange}
															value={values[field.name]}
															name={field.name}
															disabled={field.disabled}
															error={!!touched[field.name] && !!errors[field.name]}
															helperText={touched[field.name] && errors[field.name]}
														/>
													</>
												) : null}
											</Grid>
										);
									})}
								</Grid>
							</Grid>
						</Grid>

						<Box display="flex" justifyContent="end" mt="20px">
							<Button type="submit" color={data.colorBtn} variant="contained">
								{data.titleBtn}
							</Button>
						</Box>
					</form>
				)}
			</Formik>
		</Box>
	);
};

export const FormatoArticulosCompuestos = ({ data, initValues, validateSchemas }) => {
	const [setRows] = useState([]);
	const navigate = useNavigate();

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const currentDate = new Date();
	const { configData } = UseAppConfig();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;
	const [showAlert, setShowAlert] = useState(true);

	const formattedDate = currentDate.toLocaleDateString('es-ES', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric'
	});

	const handleBack = () => {
		navigate(-1);
	};

	const headerTitle = () => {
		return data.title + ' ' + initValues.nombreArt + ' (' + initValues.clave + ')';
	}

	return (
		<Grid>
			<Paper elevation={3} className="panel">
				<Box className="panel-body" sx={{ padding: "30px" }}>
					<Grid>
						<Box className="invoice-details">
							<Header title={headerTitle()} subtitle={data.description} />
							<Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0px' }}>
								<Button variant="contained" onClick={handleBack} color="success" autoFocus>
									Regresar
								</Button>
							</Box>
							<br /><br />
							<Box
								className="invoice-to"
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									justifyContent: "center",
									width: "100%",
								}}
							>
								{showAlert && (
									<Alert
										severity="warning"
										sx={{
											mb: 2,
											backgroundColor: "#ff9800",
											color: "#000",
											fontWeight: "bold",
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											textAlign: "center"
										}}
										action={
											<IconButton
												size="small"
												color="inherit"
												onClick={() => setShowAlert(false)}
											>
												<CloseIcon fontSize="small" />
											</IconButton>
										}
									>
										<h6 style={{ textAlign: "center", width: "100%" }}>
											<FontAwesomeIcon icon={faExclamationTriangle} style={{ fontSize: "18px" }} /><br />
											La edición de estos datos aplicará al Artículo Original y se verá reflejado en todos los Artículos Compuestos que lo incluyan.
										</h6>
									</Alert>
								)}

								<Grid className="list-unstyled mb0" container>
									<ArticleDetails data={data} initValues={initValues} />
									<Grid item xs={8} md={initValues.tipo === "Compuesto" ? 5 : 5}>
										<GetCompoundAmounts section={data.module} data={data} tipo={initValues.tipo} initValues={initValues} />
									</Grid>
								</Grid>
							</Box>

							<Box className="invoice-details">
								<Box>
									<CompoundDetails data={data} tipo={initValues.tipo} initValues={initValues} />
								</Box>
							</Box>
						</Box>
					</Grid>
				</Box>
			</Paper >
		</Grid >
	);
};

export const IconsDefault = ({ tipo, icon = '' }) => {
	const [iconSrc, setIconSrc] = useState('');

	useEffect(() => {
		let resolvedIcon = '';

		if (tipo === 1) tipo = 'Simple';
		if (tipo === 2) tipo = 'Compuesto';

		if (icon.length !== 0) {
			resolvedIcon = icon;
		} else {
			switch (tipo) {
				case 'Categoría':
					resolvedIcon = 'openBox.png';
					break;
				case 'Simple':
					resolvedIcon = 'barCode.png';
					break;
				case 'Compuesto':
					resolvedIcon = 'QR.png';
					break;
				default:
					resolvedIcon = 'defaultIcon.png';
			}
		}

		setIconSrc(`/assets/icons/${resolvedIcon}`);
	}, [tipo, icon]);

	return (
		<img src={iconSrc} width="20px" alt={tipo} />
	);
};

export const CargaMasiva = () => {
	const [data, setData] = useState([]);
	const { config } = UseAppConfig();

	const { enqueueSnackbar } = useSnackbar();
	const [fileName, setFileName] = useState("");

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const totalPages = Math.ceil(data.length / rowsPerPage);

	const handleFileUpload = (event) => {
		const file = event.target.files[0];
		if (!file) return;
		setFileName(file.name);

		Papa.parse(file, {
			complete: (result) => {
				const formattedData = result.data.map((row) => ({
					id: row["id"],
					tipoArtID: row["tipoArtID"],
					cuentaID: row["cuentaID"],
					unidadID: row["unidadID"],
					nombreArt: row["nombreArt"],
					descLarga: row["descLarga"],
					imagen: row["imagen"],
					status: row["status"],
					famID: row["famID"],
					clave: row["clave"],
					precioCompra: row["precioCompra"],
					precioVenta: row["precioVenta"],
					codigoBarras: row["codigoBarras"],
				}));
				setData(formattedData);
			},
			header: true,
			skipEmptyLines: true,
		});
	};

	const handleDataUpload = async () => {
		try {
			console.log('Antes de enviar datos:', data);

			const url = `${config.API_BASE_URL}/cargaMasiva/articulos`;
			const payload = { data };

			const result = await sendPostData(url, payload);

			enqueueSnackbar('Artículos agregados exitosamente al Compuesto', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			console.error('Error al intentar agregar los Artículos al Compuesto:', error);
			enqueueSnackbar('Error al intentar agregar los Artículos al Compuesto', { variant: 'error' });
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<Box justifyContent="center" style={{ maxHeight: '700px' }}>
			<Grid>
				<label htmlFor="file-upload">
					<input
						id="file-upload"
						type="file"
						accept=".csv"
						style={{ display: "none" }}
						onChange={handleFileUpload}
					/>
					<Button variant="contained" color="info" component="span">
						Cargar Archivo CSV
					</Button>
				</label>
				<hr noshade='-1'></hr>
				{fileName && (
					<p>
						<b>Archivo seleccionado:</b> {fileName}<br />
						<b>Registros importados:</b> {data.length - 1} Registros
					</p>
				)}
				{data.length > 0 && (
					<>
						<TableContainer contaniner component={Paper} sx={{ maxHeight: 350, overflowY: "auto" }}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Nombre</TableCell>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Clave</TableCell>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Tipo Artículo</TableCell>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Cuenta</TableCell>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Familia</TableCell>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Unidad</TableCell>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Precio Compra</TableCell>
										<TableCell align="center" sx={{ backgroundColor: "#0d6efd", color: "#ffffff" }}>Precio Venta</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
										<TableRow key={index}>
											<TableCell align="left">{row.nombreArt}</TableCell>
											<TableCell align="center">{row.clave}</TableCell>
											<TableCell align="center">{row.tipoArtID}</TableCell>
											<TableCell align="center">{row.cuentaID}</TableCell>
											<TableCell align="center">{row.famID}</TableCell>
											<TableCell align="center">{row.unidadID}</TableCell>
											<TableCell align="center">{formatCurrency(row.precioCompra)}</TableCell>
											<TableCell align="center">{formatCurrency(row.precioVenta)}</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
						<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", mt: 2 }}>
							<TablePagination
								rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
								component="div"
								count={data.length}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage="Registros por página:"
							/>

							<Pagination
								count={totalPages}
								page={page + 1}
								onChange={(event, value) => handleChangePage(event, value - 1)}
								color="primary"
								showFirstButton
								showLastButton
								siblingCount={2}
								sx={{ mt: 2 }}
							/>
						</Box>
					</>
				)}
			</Grid>
			<br /><br />
			<Grid container justifyContent="flex-end">
				<Button
					variant="contained"
					color="success"
					sx={{ mt: -2, mr: 1 }}
					onClick={handleDataUpload}
					disabled={!fileName}
				>
					Guardar Registros
				</Button>
			</Grid>

		</Box>
	);
};