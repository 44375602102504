import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Pagination, Checkbox, Button, useTheme, TextField, Tab, Tabs, Modal, InputAdornment, IconButton } from '@mui/material';

import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

import * as yup from "yup";
import DatePicker from "react-datepicker";
import AddIcon from "@mui/icons-material/Add";
import "react-datepicker/dist/react-datepicker.css";

import { useSnackbar } from 'notistack';

import { tokens } from "../../data/tokens";
import { usersFields } from "../../data/forms/users";

import SearchFilters from "../globals/searchFilters";
import calculatePrecioVenta from "../globals/sellPrice";

import { newArticle } from "../../data/forms/articles";
import { GeneraFormularioSimple, CargaMasiva } from "../../components/globals/globalForms";

import FileUploader from "../globals/fileUploader";
import ArticleDetails from "../globals/articleCard";

import { IconsDefault } from "../globals/globalForms";
import { searchParams } from "../../data/search/addArticles";

import { sendPostData, sendPostFileData, getPostData, getDataArray } from '../../system/getData';
import { UseAppConfig } from '../../system/config';

const formatCurrency = (value) => {
	return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

export const ViewArticle = ({ id, catID = 0, precioVenta, section = '' }) => {
	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();
	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [imagenExiste, setImagenExiste] = useState(false);

	const [artValues, setArtValues] = useState(null);
	const [loading, setLoading] = useState(true);

	const fetchDataTable = async () => {
		try {
			setIsLoading(true);
			const url = section === 'projects'
				? `${config.API_BASE_URL}/elementos/proyecto`
				: `${config.API_BASE_URL}/elementos/compuesto`;

			const jsonData = await getDataArray(url, { catID, id });
			const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

			const formatData = jsonData.map(row => {
				const { precioVentaValor, precioVentaFormatted, isPrecioVentaMenor } = calculatePrecioVenta(
					row.precio,
					row.precioVenta,
					porcentajeGlobal
				);

				return {
					...row,
					precioCompra: formatCurrency(row.precio),
					precioVenta: formatCurrency(precioVentaValor),
					isPrecioVentaMenor
				};
			});

			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
	}, [id]);

	useEffect(() => {
		if (id) {
			const fetchData = async () => {
				try {
					const url = section === 'projects'
						? `${config.API_BASE_URL}/datos/proyecto`
						: `${config.API_BASE_URL}/datos/registro`;

					const initVals = await getPostData(url, { id });
					const imagenValida = initVals.imagen && initVals.imagen.trim() !== "" ? initVals.imagen : "art.jpg";
					setArtValues({
						...initVals,
						imagen: imagenValida
					});
				} catch (error) {
					console.error('Error al cargar los valores iniciales:', error);
				} finally {
					setLoading(false);
				}
			};
			fetchData();
		}
	}, [id]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const ColorsDefault = ({ id, nombre, tipo }) => {
		const styleConfig = {
			'2': { color: 'green', cursor: 'normal' },
			'1': { color: 'blue', cursor: 'normal' },
		};

		const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
		const appliedStyle = styleConfig[tipo] || defaultStyle;

		return (
			<span style={appliedStyle}>
				{nombre}
			</span>
		);
	};

	return (
		<Grid>
			<h4>{artValues.nombreArt} ({artValues.clave})</h4><br />
			<Paper className="panel">
				<Box className="panel-body">
					<Grid container sx={{ padding: "5px", mt: "50px", mb: "-50px", justifyContent: "flex-end" }}>
						<Button
							type="submit"
							variant="contained"
							color="warning"
							className="addArticles"
							sx={{
								minWidth: 'auto',
								padding: '5px 10px',
							}}
							onClick={() => {
								const rutaBase = artValues.tipo === "Compuesto" ? "compounds/details" : "articles/details";
								navigate(`/${rutaBase}/${id}`);
							}}
						>
							Editar Artículo
						</Button>
					</Grid>
					<Grid container sx={{ mt: "30px", padding: "5px" }}>
						<ArticleDetails data={''} initValues={artValues} porcentajeGlobal={porcentajeGlobal} />
						{artValues.tipo === "Compuesto" && (
							<Grid item xs={8} md={6}>
								<b>Este Compuesto contiene los siguientes artículos:</b>
								<br /><br />
								<TableContainer
									sx={{
										width: '100%',
										maxHeight: '400px',
										overflowY: 'auto'
									}}
								>
									<Table>
										<TableHead>
											<TableRow style={{ backgroundColor: colors.grey[900], color: 'white' }}>
												<TableCell className="per5 text-center"><b>Nombre</b></TableCell>
												<TableCell className="per6 text-center"><b>Clave / SKU</b></TableCell>
												<TableCell className="per5 text-center"><b>Cantidad</b></TableCell>
												<TableCell className="per25 text-center"><b>Precio de Venta</b></TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{rows.map((row, index) => (
												<TableRow
													key={row.id}
													className={index % 2 === 0 ? 'row-white' : 'row-gray'}
												>
													<TableCell className="text-left">
														<IconsDefault tipo={row.tipo} icon='' />
														<span style={{ marginLeft: '0px', marginRight: "5px", fontSize: '10px' }}></span>
														<ColorsDefault
															id={row.id}
															nombre={row.nombreArt}
															tipo={row.tipo}
														/>
													</TableCell>
													<TableCell className="text-center">{row.hijo}</TableCell>
													<TableCell className="text-center">{row.cantidad}</TableCell>
													<TableCell
														sx={{
															textAlign: 'right',
														}}
														className={row.isPrecioVentaMenor ? 'red-text' : ''}
													>
														{row.precioVenta}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						)}
					</Grid>
				</Box>
			</Paper >
		</Grid >
	);
}

export const AddArticles = ({ proyID, catID, artID, catName, reloadData, setModalOpen }) => {
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);

	const [selectedItems, setSelectedItems] = useState([]);

	const [modalWidth, setModalWidth] = useState(false);
	const [modalHeight, setModalHeight] = useState(false);

	const [previewData, setPreviewData] = useState(false);
	const [modalSection, setModalSection] = useState(false);

	const [open, setOpen] = useState(false);
	const [viewArtID, setViewArtID] = useState(false);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const totalPages = Math.ceil(rows.length / rowsPerPage);

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();

	const fetchDataTable = async (params = {}) => {
		try {
			if (proyID && catID) {
				var jsonData = await getDataArray(`${config.API_BASE_URL}/artproy/disponibles`, { proyID, catID, params });
			} else {
				var jsonData = await getDataArray(`${config.API_BASE_URL}/elementos/disponibles`, { id: artID, params });
			}

			const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;
			const formatData = jsonData.map(row => {
				const { precioVentaValor, precioVentaFormatted, isPrecioVentaMenor } = calculatePrecioVenta(
					row.precioCompra,
					row.precioVenta,
					porcentajeGlobal
				);

				return {
					...row,
					status: row.status == 1 ? "Activo" : "Desactivado",
					precio_compra: row.precio_compra,
					precio_venta: precioVentaValor,
					isPrecioVentaMenor
				};
			});
			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const savedFilters = localStorage.getItem('searchFilters');
		savedFilters ? fetchDataTable(JSON.parse(savedFilters)) : fetchDataTable();
	}, [proyID, artID]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const handleSearchSubmit = (values) => {
		fetchDataTable(values);
	};

	const handleCheckboxChange = (id) => {
		setSelectedItems(prevSelectedItems => {
			const isSelected = prevSelectedItems.some(item => item.id === id);
			if (isSelected) {
				return prevSelectedItems.filter(item => item.id !== id);
			} else {
				if (proyID && catID) {
					return [...prevSelectedItems, { proyID, catID, id }];
				} else {
					var padreID = artID;
					return [...prevSelectedItems, { id, padreID }];
				}
			}
		});
	};

	const handleSave = async () => {
		try {
			if (proyID && catID) {
				var url = `${config.API_BASE_URL}/agrega/articulosCatProy`;
			} else {
				var url = `${config.API_BASE_URL}/agrega/articulosCompuesto`;
			}

			const params = { items: selectedItems };
			const result = await sendPostData(url, params);

			if (reloadData) {
				reloadData();
			}
			if (setModalOpen) {
				setModalOpen(false);
			}

			enqueueSnackbar('Artículos agregados exitosamente', { variant: 'success' });
		} catch (error) {
			console.error('Error al intentar agregar los Artículos al Compuesto:', error);
			enqueueSnackbar('Error al intentar agregar los Artículos al Compuesto', { variant: 'error' });
		}
	};

	const ColorsDefault = ({ id, nombre, tipo }) => {
		const styleConfig = {
			'Compuesto': { color: 'green', cursor: 'normal' },
			'Simple': { color: 'blue', cursor: 'normal' },
		};

		const defaultStyle = { color: 'red', fontSize: '13px', fontWeight: 'normal' };
		const appliedStyle = styleConfig[tipo] || defaultStyle;

		return (
			<span style={appliedStyle}>
				{nombre}
			</span>
		);
	};

	const handleRcdEdit = async (thisType, thisID, thisTipo) => {
		switch (thisType) {
			case 'viewArt':
				switch (thisTipo) {
					case 'Compuesto':
						setModalWidth('1300px');
						setModalHeight('800px');
						break;
					default:
						setModalWidth('800px');
						setModalHeight('500px');
						break;
				}
				setViewArtID(thisID);
				setModalSection('viewArticle');
				setPreviewData(true);
				break;
		}
	};

	const handleModalClose = () => {
		setPreviewData(false);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<div>
			<h5>Agregar Artículos dentro del Conjunto: {catName}</h5>
			<SearchFilters data={searchParams} onSubmit={handleSearchSubmit} />
			<br /><br />
			<TableContainer sx={{ maxHeight: 550, overflow: "auto" }}>
				<Table stickyHeader>
					<TableHead>
						<TableRow style={{ backgroundColor: colors.grey[900] }}>
							<TableCell className="per5 text-center"><b>Nombre</b></TableCell>
							<TableCell className="per5 text-center" style={{ width: '120px' }}><b>Clave / SKU</b></TableCell>
							<TableCell className="per5 text-center"><b>Unidad</b></TableCell>
							<TableCell className="per5 text-center"><b>Cuenta</b></TableCell>
							<TableCell className="per5 text-center"><b>Precio de Compra</b></TableCell>
							<TableCell className="per5 text-center"><b>Precio de Venta</b></TableCell>
							<TableCell className="per5 text-center"><b>Agregar</b></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
							<TableRow key={row.id}>
								<TableCell
									sx={{
										cursor: 'pointer',
										color: 'blue'
									}}
									className="text-left"
									onClick={() => handleRcdEdit('viewArt', row.id, row.tipo)}
								>
									<IconsDefault tipo={row.tipo} icon='' />
									<span style={{ marginLeft: '5px', fontSize: '20px' }}></span>
									<ColorsDefault
										id={row.id}
										nombre={row.nombreArt}
										tipo={row.tipo}
									/>
								</TableCell>
								<TableCell className="text-center">{row.clave}</TableCell>
								<TableCell className="text-center">{row.unidad}</TableCell>
								<TableCell className="text-center">{row.cuenta}</TableCell>
								<TableCell className="text-center">{formatCurrency(row.precioCompra)}</TableCell>
								<TableCell className="text-center">{formatCurrency(row.precio_venta)}</TableCell>
								<TableCell className="text-center">
									<Checkbox
										checked={selectedItems.some(item => item.id === row.id)}
										onChange={() => handleCheckboxChange(row.id)}
										disabled={row.precioCompra === 9 || row.precioCompra === "" || row.precioCompra === null}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", mt: 2 }}>
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage="Registros por página:"
				/>

				<Pagination
					count={totalPages}
					page={page + 1}
					onChange={(event, value) => handleChangePage(event, value - 1)}
					color="primary"
					showFirstButton
					showLastButton
					siblingCount={2}
					sx={{ mt: 2 }}
				/>
			</Box>
			<Modal
				open={previewData}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Paper
					elevation={3}
					sx={{
						width: modalWidth,
						maxHeight: modalHeight,
						overflowY: 'auto',
						padding: 3,
						backgroundColor: '#f5f5f5',
						borderRadius: 2
					}}
				>
					<ViewArticle id={viewArtID} precioVenta={''} />
				</Paper>
			</Modal>
			<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20px' }}>
				<Button variant="contained" color="secondary" onClick={handleSave}>
					Agregar Elementos
				</Button>
			</div>
		</div>
	);
};

export const CreateArticle = ({ reloadData, setModalOpen }) => {
	const initValues = {
		clave: '',
		tipoArtID: '',
		famID: '',
		cuentaID: '',
		unidadID: '',
		nombreArt: '',
		descLarga: '',
		image: ''
	};

	const dataConfig = {
		dbID: "id",
		title: "",
		key: newArticle,
		module: 'articles',
		description: "",
		titleBtn: "Crear Artículo",
		colorBtn: "secondary",
		msgSuccess: "Articulo creado exitosamente",
		msgError: "Error al crear el Registro",
		sendData: "nuevo/articulo"
	}

	const validateSchemas = yup.object().shape({
		clave: yup.string().required('Requerido'),
		tipoArtID: yup.number().required('Requerido'),
		famID: yup.number().required('Requerido'),
		cuentaID: yup.number().required('Requerido'),
		unidadID: yup.number().required('Requerido'),
		nombreArt: yup.string(),
		descLarga: yup.string(),
		image: yup.string().required('Requerido')
	});

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const [activeTab, setActiveTab] = useState(0);

	return (
		<>
			<Tabs
				value={activeTab}
				onChange={handleTabChange}
				aria-label="Creación de nuevos Registros"
				sx={{
					'& .MuiTab-root': {
						backgroundColor: '#e0e1e2',
						color: 'black',
						fontWeight: 'bold',
						borderRadius: '5px',
						marginRight: '10px',
						minHeight: '48px',
					},
					'& .Mui-selected': {
						backgroundColor: '#cfd0d1',
						color: 'black',
						fontWeight: 'bold',
						borderRadius: '5px',
						marginRight: '10px',
						minHeight: '48px',
					},
					'& .MuiTabs-indicator': {
						backgroundColor: 'transparent',
					},
				}}
			>
				<Tab label="Crear un Articulo Nuevo" />
				<Tab label="Carga masiva de artículos" />
			</Tabs>

			{activeTab === 0 && (
				<Box sx={{ paddingTop: 2 }}>
					<Typography variant="h5">
						Crear un articulo nuevo
						<br /><br />
						<small>Ingrese los datos necesarios para crear un Artículo Simple o Compuesto según sean sus necesidades:</small>
					</Typography>

					<GeneraFormularioSimple
						data={dataConfig}
						initValues={initValues}
						validateSchemas={validateSchemas}
						reloadData={reloadData}
						setModalOpen={setModalOpen}
					/>
				</Box>
			)}

			{activeTab === 1 && (
				<Box sx={{ paddingTop: 2 }}>
					<Typography variant="h5">
						Carga masiva de artículos
						<br /><br />
						<small>Seleccione el archivo .CSV para cargar los artículos necesarios de forma masiva:</small>
						<hr noshade='-1'></hr>
					</Typography>

					<Box justifyContent="center">
						<CargaMasiva
							data={dataConfig}
							initValues={initValues}
							validateSchemas={validateSchemas}
							reloadData={reloadData}
							setModalOpen={setModalOpen}
						/>
					</Box>
				</Box>
			)}
		</>
	)
}

export const EditGlobalPercent = ({ proyID }) => {
	const { config, configData } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const [sectionFormValues, setSectionFormValues] = useState({
		GlobalPercent: porcentajeGlobal,
	});

	const handleSectionChange = (e) => {
		const { name, value } = e.target;
		setSectionFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSectionSubmit = async (e) => {
		e.preventDefault();
		await handleFormSection(sectionFormValues);
	};

	const handleFormSection = async (values) => {
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/edita/porcentajeGlobal`, values);
			enqueueSnackbar('Porcentaje Editado Exitosamente', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar actualizar el Porcentaje Global', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Actualizar Porcentaje Global
				<br /><br />
				<small>El Porcentaje Global se utiliza para calcular el precio de venta de todos los artículos registrados:</small>
				<hr noshade></hr>
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1 },
				}}
				noValidate
				textAlign="center"
				marginLeft='auto'
				marginRight='auto'
				autoComplete="off"
			>
				<TextField
					type="number"
					variant="outlined"
					label="Porcentaje"
					name="GlobalPercent"
					value={sectionFormValues.GlobalPercent}
					onChange={handleSectionChange}
					sx={{
						width: "70px",
						marginBottom: 1,
					}}
				/>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSectionSubmit}
				>
					Actualizar Porcentaje
				</Button>
			</Box>
		</div>
	);
};

export const CreateSection = ({ proyID }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const [sectionFormValues, setSectionFormValues] = useState({
		proyID: proyID,
		secTitle: '',
		secDesc: ''
	});

	const handleSectionChange = (e) => {
		const { name, value } = e.target;
		setSectionFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleSectionSubmit = async (e) => {
		e.preventDefault();
		await handleFormSection(sectionFormValues);
	};

	const handleFormSection = async (values) => {
		try {
			const result = await sendPostData(`${config.API_BASE_URL}/crea/categoria`, values);
			enqueueSnackbar('Sección Guardada Exitosamente', { variant: 'success' });
			window.location.reload();
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear el nueva sección', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Crear una nueva Categoría
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="project-title"
					label="Título de la Sección"
					variant="outlined"
					name="secTitle"
					value={sectionFormValues.secTitle}
					onChange={handleSectionChange}
					fullWidth
				/>
				<TextField
					required
					id="project-description"
					label="Descripción"
					variant="outlined"
					name="secDesc"
					value={sectionFormValues.secDesc}
					onChange={handleSectionChange}
					multiline
					rows={4}
					fullWidth
				/>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSectionSubmit}
				>
					Crear Sección
				</Button>
			</Box>
		</div>
	);
};

export const EditSection = ({ data, reloadData, setModalOpen }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState(true);
	const [formValues, setFormValues] = useState({
		catID: data,
		secTitle: '',
		secDesc: ''
	});

	const fetchData = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/detalle/categoria`, { id: data });
			console.log('Datos obtenidos del API:', jsonData);
			if (jsonData && jsonData.projectDetails && jsonData.projectDetails.length > 0) {
				const row = jsonData.projectDetails[0];
				const formattedData = {
					catID: row.id || '',
					secTitle: row.nombre || '',
					secDesc: row.descripcion || ''
				};
				setFormValues(formattedData);
			} else {
				console.warn('No se encontraron datos en la respuesta del API');
			}
		} catch (error) {
			console.error('Error al obtener los datos:', error);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		fetchData();
	}, [data]);

	if (isLoading) {
		return <div>Cargando...</div>;
	}

	const handleSectionSubmit = async (e) => {
		e.preventDefault();
		await handleFormSection(formValues);
	};

	const handleFormSection = async (values) => {
		try {
			await sendPostData(`${config.API_BASE_URL}/edita/categoria`, values);
			if (reloadData) {
				reloadData();
			}
			if (setModalOpen) {
				setModalOpen(false);
			}
			enqueueSnackbar('Cambios guardados exitosamente', { variant: 'success' });
		} catch (error) {
			enqueueSnackbar('Ha ocurrido un error al intentar crear la nueva sección', { variant: 'error' });
		}
	};

	return (
		<div>
			<Typography variant="h5" gutterBottom>
				Editar los datos de esta Categoría
			</Typography>
			<Box
				component="form"
				sx={{
					'& .MuiTextField-root': { margin: 1, width: '100%' },
				}}
				noValidate
				autoComplete="off"
			>
				<TextField
					required
					id="category-title"
					label="Nombre"
					variant="outlined"
					name="secTitle"
					value={formValues.secTitle}
					onChange={(e) => setFormValues({ ...formValues, secTitle: e.target.value })}
					fullWidth
				/>
				<TextField
					required
					id="category-description"
					label="Clave"
					variant="outlined"
					name="secDesc"
					value={formValues.secDesc}
					onChange={(e) => setFormValues({ ...formValues, secDesc: e.target.value })}
					multiline
					fullWidth
				/>
				<Button
					variant="contained"
					sx={{
						marginTop: 2,
						backgroundColor: 'green',
						color: 'white',
						display: 'block',
						marginLeft: 'auto',
						marginRight: 'auto'
					}}
					onClick={handleSectionSubmit}
				>
					Guardar Cambios
				</Button>
			</Box>
		</div>
	);
};

export const CreateProject = ({ data }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const [projectFormValues, setProjectFormValues] = useState(() => {
		if (data) {
			return {
				proyId: data.id || "",
				proyTitle: data.nombre || "",
				proyDesc: data.descripcion || "",
				proyImagen: data.imagen || null,
				startDate: data.fechaCreacion || "",
				endDate: data.fechaFinalizacion || "",
				utilityFactor: data.porcentajeUtilidad || 0,
				clientName: data.clientName || "",
				company: data.company || "",
				phone: data.phone || "",
				email: data.email || "",
			};
		} else {
			return {
				proyTitle: "",
				proyDesc: "",
				proyImagen: null,
				startDate: "",
				endDate: "",
				utilityFactor: 0,
				clientName: "",
				company: "",
				phone: "",
				email: "",
			};
		}
	});

	const handleProjectChange = (e) => {
		const { name, value } = e.target;
		setProjectFormValues((prevValues) => ({
			...prevValues,
			[name]: value,
		}));
	};

	const handleFileUpload = (field, file) => {
		if (file) {
			const imageUrl = URL.createObjectURL(file);
			setProjectFormValues((prevValues) => ({
				...prevValues,
				[field]: file,
				proyImagenPreview: imageUrl,
			}));
		}
	};

	const handleFormSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData();

		if (projectFormValues.proyImagen instanceof File) {
			console.log("✅ Archivo válido:", projectFormValues.proyImagen);
			formData.append("image", projectFormValues.proyImagen);
		}

		Object.keys(projectFormValues).forEach((key) => {
			if (key !== "proyImagen" && key !== "proyImagenPreview") {
				formData.append(key, projectFormValues[key]);
			}
		});

		console.log("Enviando FormData...");
		for (let pair of formData.entries()) {
			console.log(pair[0], pair[1]);
		}

		try {
			const url = data ? `${config.API_BASE_URL}/edita/proyecto` : `${config.API_BASE_URL}/nuevo/proyecto`;
			await sendPostFileData(url, formData);
			enqueueSnackbar("Proyecto Guardado Exitosamente", { variant: "success" });
			window.location.reload();
		} catch (error) {
			console.error("Error al subir el archivo:", error);
			enqueueSnackbar("Error al subir el archivo.", { variant: "error" });
		}
	};

	const uploadParams = {
		width: '408px',
		height: '130px'
	};

	return (
		<Box component="form" noValidate autoComplete="off" sx={{ p: 2, bgcolor: "background.paper", borderRadius: 2, boxShadow: 3 }}>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
				<Typography variant="h5" fontWeight={600}>
					{data ? "Editar Proyecto" : "Crear Nuevo Proyecto"}
				</Typography>
				<Box>
					<Button variant="contained" color={data ? "secondary" : "primary"} sx={{ fontWeight: 600 }} onClick={handleFormSubmit}>{data ? "Guardar Cambios" : "Crear Proyecto"}</Button>
				</Box>
			</Box>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8}>
					<Typography variant="h6" fontWeight={500}>Información general</Typography>
					<Grid item xs={12} md={12} sx={{ mr: 2 }}>
						<TextField label="Nombre de proyecto" name="proyTitle" value={projectFormValues.proyTitle} onChange={handleProjectChange} fullWidth margin="normal" required />
						<TextField label="Descripción (Opcional)" name="proyDesc" value={projectFormValues.proyDesc} onChange={handleProjectChange} multiline rows={8} fullWidth margin="normal" />
					</Grid>

					<Grid container spacing={1} sx={{
						mt: 2,
						width: '98%',
						padding: '10px',
						marginLeft: '1px',
						border: "2px solid #ccc",
						backgroundColor: "#f9f9f9"
					}}>
						<Box
							sx={{
								width: "100px",
								height: "auto",
								borderRadius: "10px",
								display: "flex",
								marginRight: '10px',
								flexDirection: "column",
								alignItems: "center",
								justifyContent: "center",
								textAlign: "center",
								cursor: "pointer",
								backgroundColor: "#f1f2f3",
								"&:hover": { backgroundColor: "#f1f1f1" },
							}}
							onClick={() => document.getElementById("file-upload").click()}
						>
							<>
								<AddIcon sx={{ fontSize: 40, color: "#999" }} />
							</>
						</Box>
						<FileUploader
							setFieldValue={handleFileUpload}
							value={projectFormValues.proyImagenPreview}
							params={uploadParams}
						/>
					</Grid>
				</Grid>
				<Grid item xs={12} md={4} sx={{ borderLeft: 1, pl: 3 }}>
					<Typography variant="h6" fontWeight={500}>Información de cliente (Opcional)</Typography>
					<Grid item xs={12} mt={3}>
						<TextField
							label="Ingrese la Fecha de inicio del Proyecto"
							type="date"
							fullWidth
							InputLabelProps={{ shrink: true }}
							name="startDate"
							value={projectFormValues.startDate || ""}
							onChange={handleProjectChange}
						/>
					</Grid>
					<Grid item xs={12} mt={2}>
						<TextField
							label="Ingrese la Fecha de Final del Proyecto"
							type="date"
							fullWidth
							InputLabelProps={{ shrink: true }}
							name="endDate"
							value={projectFormValues.endDate || ""}
							onChange={handleProjectChange}
						/>
					</Grid>
					<TextField
						label="Factor de utilidad"
						name="utilityFactor"
						type="number"
						value={projectFormValues.utilityFactor ?? 0}
						onChange={(e) => {
							const value = e.target.value;

							// Permitimos números vacíos para que el usuario pueda borrar y volver a escribir
							if (value === '' || (/^\d{1,3}$/.test(value) && parseInt(value, 10) >= 0)) {
								handleProjectChange(e);
							}
						}}
						margin="normal"
						inputProps={{
							min: 0,
							step: 'any',
							inputMode: 'decimal',
							maxLength: 3, // Evita más de 3 caracteres en algunos navegadores
						}}
						InputProps={{
							endAdornment: <InputAdornment position="end">%</InputAdornment>,
						}}
					/>
					<TextField label="Nombre de cliente" name="clientName" value={projectFormValues.clientName} onChange={handleProjectChange} fullWidth margin="normal" />
					<TextField label="Empresa" name="company" value={projectFormValues.company} onChange={handleProjectChange} fullWidth margin="normal" />
					<TextField label="Teléfono" name="phone" value={projectFormValues.phone} onChange={handleProjectChange} fullWidth margin="normal" InputProps={{ startAdornment: <InputAdornment position="start">🇲🇽 +52</InputAdornment> }} />
					<TextField label="Correo electrónico" name="email" value={projectFormValues.email} onChange={handleProjectChange} fullWidth margin="normal" />
				</Grid>
			</Grid>
		</Box>
	);
};

export const CreateUser = ({ data, reloadData, setModalOpen }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const initValues = {
		nombre: '',
		apPat: '',
		apMat: '',
		correo: '',
		telefono1: '',
		telefono2: '',
		direccion: '',
		tipoUsuID: '',
		status: ''
	};

	const dataConfig = {
		dbID: "id",
		title: "",
		key: usersFields,
		section: 'users',
		module: 'users',
		description: "",
		titleBtn: "Nuevo Usuario",
		colorBtn: "secondary",
		msgSuccess: "Usuario creado exitosamente",
		msgError: "Error al crear el usuario",
		sendData: "nuevo/usuario"
	}

	const validateSchemas = yup.object().shape({
		nombre: yup.string().required('Requerido'),
		apPat: yup.string().required('Requerido'),
		apMat: yup.string().required('Requerido'),
		correo: yup.string().email('Correo inválido').required('Requerido'),
		telefono1: yup.string(),
		telefono2: yup.string(),
		direccion: yup.string(),
		tipoUsuID: yup.number().required('Requerido'),
	});

	return (
		<>
			<Typography variant="h5" gutterBottom>
				Creación de Nuevo Usuario
			</Typography>
			<small>Ingrese los datos necesarios para crear un Nuevo Usuario</small>

			<GeneraFormularioSimple
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
				reloadData={reloadData}
				setModalOpen={setModalOpen}
			/>
		</>
	)
};

export const EditUser = ({ userID, reloadData, setModalOpen }) => {
	const { config } = UseAppConfig();
	const { enqueueSnackbar } = useSnackbar();

	const [initValues, setInitValues] = useState(null);
	const [loading, setLoading] = useState(true);

	const dataConfig = {
		dbID: "id",
		title: "",
		key: usersFields,
		section: 'users',
		module: 'users',
		description: "",
		titleBtn: "Editar Usuario",
		colorBtn: "secondary",
		msgSuccess: "Usuario editado exitosamente",
		msgError: "Error al editar el usuario",
		getData: "detalle/usuario",
		sendData: "edita/usuario"
	}

	const validateSchemas = yup.object().shape({
		nombre: yup.string().required('Requerido'),
		apPat: yup.string().required('Requerido'),
		apMat: yup.string().required('Requerido'),
		correo: yup.string().email('Correo inválido').required('Requerido'),
		telefono1: yup.string(),
		telefono2: yup.string(),
		direccion: yup.string(),
		tipoUsuID: yup.number().required('Requerido'),
	});

	const fetchDataTable = async () => {
		try {
			const initVals = await getPostData(`${config.API_BASE_URL}/${dataConfig.getData}`, { id: userID });
			setInitValues(initVals);
			console.log('Datos del Usuario: ' + JSON.stringify(initVals));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
	}, [userID, dataConfig, config]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	return (
		<>
			<Typography variant="h5" gutterBottom>
				Edición de datos de Usuario
			</Typography>
			<small>Ingrese los datos necesarios para editar los datos de este Usuario</small>

			<GeneraFormularioSimple
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
				reloadData={reloadData}
				setModalOpen={setModalOpen}
			/>
		</>
	);
};