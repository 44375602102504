import React, { useEffect, useState, useRef } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Box, Modal, TextField, Tabs, Tab, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, useTheme, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { Formik } from "formik";
import { useSnackbar } from 'notistack';
import { tokens } from "../../data/tokens";
import { sendPostData, getPostData, getDataArray } from '../../system/getData';

import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faSave } from '@fortawesome/free-solid-svg-icons';

import { Header } from "../basics/Header";
import { UseAppConfig } from '../../system/config';

import ConfirmationDialog from "../globals/confirmation";

import { CreateSection, ViewArticle, EditGlobalPercent, AddArticles } from "../../components/projects/addElements";

import { IconsDefault } from "../globals/globalForms";
import { ExpandableDataTable } from "../../components/globals/newDataTable";


import { GeneraFormularioSimple } from "../../components/globals/globalForms";
import { editArticle } from "../../data/forms/articles";

import * as yup from "yup";

export const GetArticlesList = ({ data }) => {
	const { configData } = UseAppConfig();
	const [modalOpen, setModalOpen] = useState(false);
	const [modalSection, setModalSection] = useState(false);

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const renderModalContent = () => {
		switch (modalSection) {
			case 'addCategory':
				return <CreateSection proyID={1} />;
			case 'editPercent':
				return <EditGlobalPercent data={1} />;
			default:
				return null;
		}
	};

	return (
		<Box m="20px">
			<Header title={data.title} subtitle={data.description} />
			<Box>
				<h6>Todos los Precios de Venta se aplican con el Porcentaje Global del: {porcentajeGlobal}%</h6>
			</Box>
			<ExpandableDataTable data={data} />
			<Modal
				open={modalOpen}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Paper
					elevation={3}
					sx={{
						width: modalSection === 'editPercent' ? '400px' : '50%',
						padding: 3,
						backgroundColor: '#f5f5f5',
						borderRadius: 2
					}}
				>
					{renderModalContent()}
				</Paper>
			</Modal>
		</Box>
	)
};

export const CompoundDetails = ({ data, tipo, initValues }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [currentCantidad, setCurrentCantidad] = useState(initValues.precioCompra);
	const [selectedOption, setSelectedOption] = useState("automatico");

	const theme = useTheme();
	const colors = tokens(theme.palette.mode);

	const { config, configData } = UseAppConfig();

	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const [OpenConfirm, setConfirmOpen] = useState(false);
	const [ConfirmValue, setConfirmValue] = useState('');

	const [RcdID, setRcdID] = useState(0);
	const [RcdName, setRcdName] = useState('');

	const [artID, setArtID] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [modalSection, setModalSection] = useState(false);

	const [modalWidth, setModalWidth] = useState(false);
	const [modalHeight, setModalHeight] = useState(false);

	const [activeTab, setActiveTab] = useState(0);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const handleChange = (id, totalGeneral, newValue) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id
					? { ...row, cantidad: newValue, isChanged: true, total: row.precio * newValue, percentage: totalGeneral > 0 ? (row.precio * newValue / totalGeneral) * 100 : 0 }
					: row
			)
		);
	};

	const handleDisabledr = (id, newValue) => {
		setRows((prevRows) =>
			prevRows.map((row) =>
				row.id === id
					? { ...row, isConfirmed: true, isChanged: false }
					: row
			)
		);
	};

	const handleClick = async (id, newValue) => {
		if (newValue && newValue > 0) {
			try {
				const result = await sendPostData(`${config.API_BASE_URL}/edita/compuesto`, { id, cantidad: newValue });
				console.log('Formulario actualizado exitosamente:', result);
				handleDisabledr(id, newValue);
				enqueueSnackbar('Cantidad actualizada exitosamente', { variant: 'success' });
				try {
					const precioVentaResponse = await sendPostData(`${config.API_BASE_URL}/consulta/precioVenta`, { id: initValues.id });
					console.log('El nuevo Precio de Venta es:', JSON.stringify(precioVentaResponse));
					if (Array.isArray(precioVentaResponse) && precioVentaResponse.length > 0) {
						const precioVenta = precioVentaResponse[0];
						console.log('Precio de Compra:', precioVenta.precioCompra);
						setCurrentCantidad(precioVenta.precioCompra);
					}
				} catch (error) {
					console.error('Error al consultar el nuevo Precio de Venta:', error);
				}
			} catch (error) {
				console.error('Error al actualizar la cantidad de este artículo:', error);
				enqueueSnackbar('Error al Actualizar la Cantidad de este Artículo', { variant: 'error' });
			}
		}
	};

	const fetchDataTable = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/${data.sendData}`, { id: initValues.id });
			const totalGeneral = jsonData.reduce((sum, row) => sum + (row.precio * row.cantidad), 0);

			const formatData = jsonData.map(row => {
				const total = row.precio * row.cantidad;
				const percentage = totalGeneral > 0 ? (total / totalGeneral) * 100 : 0;
				return {
					...row,
					status: row.statusElemento == 1 ? 'Activado' : 'Desactivado',
					cantidadAnterior: row.cantidad,
					total: total,
					totalGeneral: totalGeneral,
					percentage: percentage
				};
			});

			setRows(formatData);
			console.log('Detalles del Registro: ' + JSON.stringify(jsonData));
		} catch (error) {
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchDataTable();
		if (initValues.precioVenta === "") {
			setSelectedOption("automatico");
		} else if (initValues.precioVenta < 0) {
			setSelectedOption("porcentaje");
		} else if (initValues.precioVenta > 0) {
			setSelectedOption("personalizado");
		}
	}, [initValues.id, data.sendData]);

	if (loading) {
		return <div>Cargando...</div>;
	}

	const reloadData = async () => {
		try {
			fetchDataTable();
		} catch (error) {
			enqueueSnackbar('Error al recargar los datos', { variant: 'error' });
		}
	};

	const handleConfirm = async () => {
		try {
			const delUsrID = await getPostData(`${config.API_BASE_URL}/retira/articuloCompuesto`, { id: RcdID });
			enqueueSnackbar('El Artículo ha sido Retirado de este Compuesto exitosamente', { variant: 'error' });
			reloadData();
			handleConfirmClose();
		} catch (error) {
			enqueueSnackbar('Error al intentar Retirar el Artículo de este Compuesto', { variant: 'error' });
			console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
		}
		console.log('Confirmado');
	};

	const handleFormDelete = async (params) => {
		setRcdID(params.id);
		setRcdName(params.RcdName);
		setConfirmValue(params.ConfirmValue);
		setConfirmOpen(true);
	};

	const handleConfirmClose = () => {
		setConfirmOpen(false);
	};

	const handleRcdEdit = async (thisType, thisID, thisTipo) => {
		setArtID(thisID);
		setModalOpen(true);

		switch (thisType) {
			case 'viewArt':
				switch (thisTipo) {
					case 'Compuesto':
						setModalWidth('1300px');
						setModalHeight('800px');
						break;
					default:
						setModalWidth('800px');
						setModalHeight('500px');
						break;
				}
				setModalSection('viewArticle');
				break;
			case 'addArticles':
				setModalWidth('950px');
				setModalHeight('1000px');
				setModalSection('addArticles');
				break;
		}
	};

	const renderModalContent = () => {
		switch (modalSection) {
			case 'viewArticle':
				return <ViewArticle id={artID} precioVenta={''} />
			case 'addArticles':
				return <AddArticles artID={artID} reloadData={reloadData} setModalOpen={setModalOpen} />;
			default:
				return null;
		}
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	const ColorsDefault = ({ id, nombre, tipo }) => {
		const styleConfig = {
			'Compuesto': { color: 'green', cursor: 'normal' },
			'Simple': { color: 'blue', cursor: 'normal' },
		};

		const defaultStyle = { color: 'red', fontSize: '14px', fontWeight: 'normal' };
		const appliedStyle = styleConfig[tipo] || defaultStyle;

		return (
			<span style={appliedStyle}>
				{nombre}
			</span>
		);
	};

	return (
		<Grid spacing={3}>
			{(() => {
				const dataConfig = {
					dbID: "id",
					title: "Editar los datos de " + initValues.nombreArt,
					key: editArticle,
					section: 'articles',
					module: 'articles',
					description: "Ingrese los datos necesarios para editar los datos de este artículo",
					titleBtn: "Actualizar Datos",
					colorBtn: "secondary",
					msgSuccess: "Articulo editado exitosamente",
					msgError: "Error al editar el Articulo",
					getData: "detalle/articulo",
					sendData: "edita/articulo"
				}

				const validateSchemas = yup.object().shape({
					clave: yup.string().required('Requerido'),
					famID: yup.number().required('Requerido'),
					cuentaID: yup.number().required('Requerido'),
					unidadID: yup.number().required('Requerido'),
					nombreArt: yup.string().required('Requerido'),
					descLarga: yup.string().required('Requerido'),
					status: yup.number().required('Requerido')
				});

				return (
					<>
						<Box>
							<Box sx={{ mt: 4, borderBottom: 1, borderColor: 'divider' }}>
								{tipo === 'Simple' ? (
									<>
									</>
								) : (
									<>
										<Tabs
											value={activeTab}
											onChange={handleTabChange}
											aria-label="basic tabs example"
											sx={{
												'& .MuiTab-root': {
													backgroundColor: 'darkblue',
													color: 'white',
													fontWeight: 'bold',
													borderRadius: '5px',
													margin: '0 5px',
													minHeight: '48px',
												},
												'& .Mui-selected': {
													backgroundColor: 'cornflowerblue',
													color: 'white',
													fontWeight: 'bold',
													borderRadius: '5px',
													margin: '0 5px',
													minHeight: '48px',
												},
												'& .MuiTabs-indicator': {
													backgroundColor: 'transparent',
												},
											}}
										>
											<Tab label="Listado de Artículos" />
											<Tab label="Editar Compuesto" />
										</Tabs>
									</>
								)}
							</Box>

							{tipo === 'Simple' ? (
								<>
									{activeTab === 0 && (
										<Box sx={{ padding: 2 }}>
											<GeneraFormularioSimple
												data={dataConfig}
												initValues={initValues}
												validateSchemas={validateSchemas}
											/>
										</Box>
									)}
								</>
							) : (
								<>
									{activeTab === 0 && (
										<Box>
											<Button
												type="submit"
												variant="contained"
												color="primary"
												className="addArticles"
												style={{ marginTop: '100px', marginRight: '0px' }}
												sx={{
													minWidth: 'auto',
													padding: '5px 10px',
												}}
												onClick={() => handleRcdEdit('addArticles', initValues.id, '')}
											>
												Cargar Artículos
											</Button>
											<Box sx={{ textAlign: 'left' }}>
												<br /><br />
												<h3>Este Compuesto está conformado por los siguientes Artículos:</h3>
												Tenga en cuenta que al modificar las cantidades tendrá que guardar cada registro de manera independiente
											</Box><br />
											<TableContainer>
												<Table>
													<TableHead>
														<TableRow style={{ backgroundColor: colors.grey[900] }}>
															<TableCell className="per5 text-center"><b>Nombre</b></TableCell>
															<TableCell className="per5 text-center" style={{ width: '120px' }}><b>Clave / SKU</b></TableCell>
															<TableCell className="per25 text-center"><b>Familia</b></TableCell>
															<TableCell className="per25 text-center"><b>Unidad</b></TableCell>
															<TableCell className="per25 text-center"><b>Cuenta</b></TableCell>
															<TableCell className="per25 text-center"><b>Precio de Compra</b></TableCell>
															<TableCell className="per5 text-center"><b>Cantidad</b></TableCell>
															<TableCell className="per5 text-center"><b>Total</b></TableCell>
															<TableCell className="per25 text-center"><b>% Participación</b></TableCell>
															<TableCell className="per5 text-center"><b>Acciones</b></TableCell>
														</TableRow>
													</TableHead>
													<TableBody>
														{rows.map((row) => (
															<TableRow key={row.id}>
																<TableCell
																	sx={{
																		cursor: 'pointer',
																		color: 'blue'
																	}}
																	className="text-left"
																	onClick={() => handleRcdEdit('viewArt', row.rcdID, row.nombreTipo)}
																>
																	<IconsDefault tipo={row.tipo} icon='' />
																	<span style={{ marginLeft: '5px', fontSize: '20px' }}></span>
																	<ColorsDefault
																		id={row.id}
																		nombre={row.nombre}
																		tipo={row.nombreTipo}
																	/>
																</TableCell>
																<TableCell className="text-center">{row.hijo}</TableCell>
																<TableCell className="text-center">{row.nombreFamilia}</TableCell>
																<TableCell className="text-center">{row.nombreUnidad}</TableCell>
																<TableCell className="text-center">{row.nombreCuenta}</TableCell>
																<TableCell style={{ textAlign: 'right' }}>
																	{formatCurrency(row.precio)}
																</TableCell>

																<TableCell className="text-center">
																	<input
																		type="number"
																		value={row.cantidad}
																		style={{ width: '60px' }}
																		className="input-number"
																		onChange={(e) => handleChange(row.id, row.totalGeneral, e.target.value)}
																		disabled={!row.statusElemento || row.statusElemento === 'valor_especifico'}
																	/>
																</TableCell>
																<TableCell style={{ textAlign: 'right' }} className={`${row.isConfirmed ? 'green-text' : row.isChanged ? 'yellow-text' : ''}`}>
																	{formatCurrency(row.total)}
																</TableCell>
																<TableCell className={`text-center ${row.isConfirmed ? 'green-text' : row.isChanged ? 'yellow-text' : ''}`}>
																	{row.percentage.toFixed(2)}%
																</TableCell>
																<TableCell className="text-center">
																	<Box sx={{ display: 'flex', gap: '3px' }}>
																		<Button
																			type="submit"
																			color="inherit"
																			variant="contained"
																			sx={{
																				minWidth: 'auto',
																				padding: '5px 10px',
																				backgroundColor: row.isChanged ? 'green' : undefined,
																				'&:disabled': {
																					backgroundColor: 'grey',
																				},
																			}}
																			disabled={!row.isChanged}
																			onClick={() => handleClick(row.id, row.cantidad)}
																		>
																			<FontAwesomeIcon icon={faSave} style={{ color: "white" }} />
																		</Button>
																		<Button
																			type="submit"
																			color="error"
																			variant="contained"
																			sx={{ minWidth: 'auto', padding: '5px 10px' }}
																			onClick={() => {
																				handleFormDelete({
																					id: row.id,
																					ConfirmValue: 7,
																					RcdName: row.hijo
																				});
																			}}
																		>
																			<FontAwesomeIcon icon={faTrash} />
																		</Button>
																	</Box>
																</TableCell>
															</TableRow>
														))}
													</TableBody>
												</Table>
											</TableContainer>
											<Modal
												open={modalOpen}
												onClose={handleModalClose}
												aria-labelledby="modal-modal-title"
												aria-describedby="modal-modal-description"
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
												}}
											>
												<Paper
													elevation={3}
													sx={{
														width: modalWidth,
														maxHeight: modalHeight,
														overflowY: 'auto',
														padding: 3,
														backgroundColor: '#f5f5f5',
														borderRadius: 2
													}}
												>
													{renderModalContent()}
												</Paper>
											</Modal>
											<ConfirmationDialog
												open={OpenConfirm}
												onClose={handleConfirmClose}
												onConfirm={handleConfirm}
												statusValue={ConfirmValue}
												keyValue={RcdName}
											/>
										</Box>
									)}

									{activeTab === 1 && (
										<Box sx={{ padding: 2, paddingTop: 10 }}>
											<GeneraFormularioSimple
												data={dataConfig}
												initValues={initValues}
												validateSchemas={validateSchemas}
											/>
										</Box>
									)}
								</>
							)}
						</Box>
					</>
				);

			})()}
		</Grid>
	);
};

export const GetCompoundAmounts = ({ section, data, precioReal, precioActual, projData, reloadData, setModalOpen, tipo, initValues }) => {
	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const navigate = useNavigate();
	const [rows, setRows] = useState([]);
	const { enqueueSnackbar } = useSnackbar();
	const [loading, setLoading] = useState(true);
	const [selectedOption, setSelectedOption] = useState("automatico");

	const { config, configData } = UseAppConfig();
	const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

	const [nuevoPrecioVenta, setNuevoPrecioVenta] = useState('');

	let valoresPrecios = {};

	const fetchDataTable = async () => {
		try {
			const jsonData = await getDataArray(`${config.API_BASE_URL}/${data.sendData}`, { id: initValues.id });
			setRows(jsonData);
		} catch (error) {
			console.error('Error al obtener los datos:', error);
			setRows([]);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!initValues.precioVentaFinal || initValues.precioVentaFinal === "" || initValues.precioVentaFinal == 0) {
			initValues.precioVentaFinal = precioReal;
		}

		fetchDataTable();

		if (section === "projects") {
			if ((Math.abs(initValues.valorReferencia) == projData.porcentajeUtilidad) && projData.porcentajeUtilidad != 0) {
				setSelectedOption("automatico");
			} else if (initValues.valorReferencia < 0) {
				setSelectedOption("porcentajeUtilidad");
				setNuevoPrecioVenta(initValues.valorReferencia)
			} else if (initValues.valorReferencia == 0 && initValues.valorReferencia.length != 0 && initValues.precioVentaFinal != initValues.precioVenta) {
				setSelectedOption("personalizado");
				setNuevoPrecioVenta(initValues.precioVentaFinal)
			} else if (initValues.precioVentaFinal === precioReal) {
				setSelectedOption("real");
				setNuevoPrecioVenta(initValues.precioReal)
			}

		} else {
			if (initValues.precioVenta === "") {
				setSelectedOption("automatico");
			} else if (initValues.precioVenta < 0) {
				setSelectedOption("porcentajeUtilidad");
			} else if (initValues.precioVenta > 0) {
				setSelectedOption("personalizado");
			}
		}
	}, [initValues.id, data.sendData]);

	const calcularPrecioVentaFinal = (values) => {
		let artID = initValues.artID;
		let catID = initValues.catID;
		let valorReferencia = 0;
		let precioVenta = 0;
		let precioVentaFinal = 0;

		const precioCompra = values.precioCompra || 0;
		const unidades = values.elementos > 0 ? values.elementos : 1;

		const precioVentaNumerico = typeof values.precioVenta === "string"
			? parseFloat(values.precioVenta.replace(/[^0-9.]/g, "")) || 0
			: values.precioVenta || 0;

		switch (selectedOption) {
			case "real":
				precioVentaFinal = precioCompra / (1 - (section === "projects" ? projData.porcentajeUtilidad / 100 : porcentajeGlobal / 100));
				precioVenta = precioVentaFinal;
				valorReferencia = 0;
				break;
			case "personalizado":
				precioVentaFinal = precioVentaNumerico;
				precioVenta = precioVentaFinal;
				valorReferencia = 0;
				break;
			case "automatico":
				precioVentaFinal = precioCompra / (1 - (section === "projects" ? projData.porcentajeUtilidad / 100 : porcentajeGlobal / 100));
				precioVenta = precioVentaFinal;
				valorReferencia = section === "projects" ? -Math.abs(projData.porcentajeUtilidad) : 0;
				break;
			case "porcentajeUtilidad":
				precioVentaFinal = precioCompra / (1 - Math.abs(values.precioVenta) / 100);
				precioVenta = precioVentaFinal;
				valorReferencia = values.precioVenta;
				break;
			default:
				precioVentaFinal = precioVentaNumerico;
		}

		valoresPrecios = {
			artID: artID,
			catID: catID,
			unidades: unidades,
			valorReferencia: parseInt(valorReferencia, 10) || 0,
			precioVentaFinal: Math.round(precioVenta * unidades)
		};

		return formatCurrency(precioVentaFinal * unidades);
	};

	const handleSaleSubmit = async (values) => {
		if (section === "projects") {
			await actualizaPrecioVenta(valoresPrecios);
		} else {
			switch (tipo) {
				case 'Simple':
					await actualizaPrecioCompra(values);
					break;
				case 'Compuesto':
					await actualizaPrecioVenta(values);
					break;
			}
		}
	};

	const actualizaPrecioCompra = async (values) => {
		if (selectedOption === "automatico") {
			values.precioVenta = 0;
		}
		try {
			await sendPostData(`${config.API_BASE_URL}/edita/precioCompra`, values);
			enqueueSnackbar(data.msgSuccess, { variant: 'success' });
			navigate(0);
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	const actualizaPrecioVenta = async (values) => {
		switch (selectedOption) {
			case "real":
				if (values.unidades == 1) {
					values.precioVentaFinal = 0;
				}
				break;
			case "automatico":
				values.precioVenta = 0;
				break;
		}
		const currentValues = {
			id: initValues.id,
			...values,
		};

		try {
			const endpoint = section === "projects"
				? `${config.API_BASE_URL}/edita/precioVentaProyecto`
				: `${config.API_BASE_URL}/edita/precioVenta`;

			await sendPostData(endpoint, currentValues);
			enqueueSnackbar('Precio de Venta actualizado exitosamente', { variant: 'success' });
			if (reloadData) {
				reloadData();
				setModalOpen(false);
			} else {
				navigate(0);
			}
		} catch (error) {
			enqueueSnackbar(data.msgError, { variant: 'error' });
		}
	};

	if (loading) {
		return <div>Cargando...</div>;
	}

	return (
		<Box>
			{section === "projects" && (
				<Box mb={2}>
					<Box display="flex" alignItems="center" gap={1}>
						<Typography variant="h6" fontWeight="bold" color="primary">
							<b>Clave / SKU:</b>
						</Typography>
						<Typography>
							{initValues.clave}
						</Typography>
					</Box>
					<Box display="flex" alignItems="center" gap={1}>
						<Typography variant="h6" fontWeight="bold" color="primary">
							<b>Nombre del Artículo:</b>
						</Typography>
						<Typography>
							{initValues.nombreArt}
						</Typography>
					</Box>
					<Box sx={{ borderBottom: "1px solid #ccc", my: 2 }}></Box>
				</Box>
			)}

			<Grid item xs={12} sm={12}>
				<Box sx={{ backgroundColor: "#f1f2f3", pt: "20px", borderRadius: 2, boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)" }}>
					<Formik initialValues={initValues} onSubmit={handleSaleSubmit}>
						{({ values, handleChange, setFieldValue, handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<Grid container spacing={2} alignItems="center" sx={{ display: "flex", justifyContent: "center" }}>
									<Grid
										item
										xs={12}
										sm={3}
										sx={section === "projects" ? {
											backgroundColor: "#f0f0f0",
											borderRight: "2px solid #ccc",
											padding: "10px",
											borderRadius: "5px"
										} : {}}
									>

										{section === "projects" && (
											<>
												<Typography fontWeight="bold">
													Precio de Compra
												</Typography>
												<Typography fontSize={18} fontWeight="bold" sx={{ color: "green" }}>
													{formatCurrency(values.precioCompra)}
												</Typography>

												<Typography sx={{ mt: '20px' }} fontWeight="bold">
													Precio de Venta LP:
												</Typography>
												<Typography fontSize={18} fontWeight="bold" sx={{ color: "orange" }}>
													{formatCurrency(precioReal)}
												</Typography>

												<Typography sx={{ mt: '20px' }} fontWeight="bold">
													Precio de Venta Proy.
												</Typography>
												<Typography fontSize={18} fontWeight="bold" sx={{ color: "blue" }}>
													{formatCurrency(precioActual)}
												</Typography>

												<Typography sx={{ mt: '20px' }} fontWeight="bold">
													No. de Elementos Actual:
												</Typography>
												<Typography fontSize={18} fontWeight="bold" sx={{ color: "brown" }}>
													{initValues.elementos} {initValues.elementos == 1 ? "Unidad" : "Unidades"}
												</Typography>
											</>
										)}
									</Grid>

									<Grid
										container
										item
										mb={'30px'}
										xs={12}
										sm={section === "projects" ? 9 : 12}
										justifyContent="center"
									>
										{section === "articles" && (
											<>
												<Grid item xs={12} sm={3}>
													<Typography sx={{ mb: '10px' }} fontWeight="bold">Precio de Compra</Typography>
													<TextField
														name="precioCompra"
														label="Precio de Compra"
														type="number"
														value={values.precioCompra >= 0 ? values.precioCompra : ""}
														onChange={(e) => {
															let inputValue = e.target.value.replace(/[^0-9.]/g, "");
															if (/^\d+(\.\d{0,2})?$/.test(inputValue)) {
																setFieldValue("precioCompra", inputValue === "" ? "" : Number(inputValue));
															}
														}}
														InputProps={{
															startAdornment: <InputAdornment position="start">$</InputAdornment>
														}}
														size="small"
														fullWidth
													/>
												</Grid>

												<Grid>
													<Typography sx={{ mt: '40px', ml: '15px', mr: '15px' }} fontWeight="bold"></Typography>
												</Grid>
											</>
										)}

										<Grid item xs={12} sm={3}>
											<Typography sx={{ mb: '10px' }} fontWeight="bold">Valor de Referencia</Typography>
											{section === "projects" && (
												<>
													{selectedOption === "real" && (
														<TextField
															type="text"
															name="precioVenta"
															label="$ Precio de Venta LP"
															value={precioReal ? new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(precioReal) : ""}
															size="small"
															disabled
															fullWidth
														/>
													)}
												</>
											)}

											{selectedOption === "personalizado" && (
												<TextField
													type="text"
													name="precioVenta"
													label="Precio Particular"
													value={
														values.precioVenta
															? new Intl.NumberFormat("es-MX").format(Number(values.precioVenta))
															: ""
													}
													onChange={(e) => {
														let rawValue = e.target.value.replace(/[^0-9]/g, "");
														setFieldValue("precioVenta", rawValue);
													}}
													InputProps={{
														startAdornment: <InputAdornment position="start">$</InputAdornment>,
													}}
													size="small"
													fullWidth
												/>
											)}

											{selectedOption === "automatico" && (
												<TextField
													type="text"
													name="precioVenta"
													label={
														section === "projects"
															? `${Math.abs(projData.porcentajeUtilidad).toFixed(2)}% Utilidad del Proyecto`
															: `${Math.abs(porcentajeGlobal).toFixed(2)}% Utilidad Global`
													}
													value={`${Math.abs(section === "projects" ? projData.porcentajeUtilidad : porcentajeGlobal || 0).toFixed(2)}%`}
													size="small"
													disabled
													fullWidth
												/>
											)}

											{selectedOption === "porcentajeUtilidad" && (
												<TextField
													type="number"
													name="precioVenta"
													label="Utilidad Particular"
													value={values.precioVenta < 0 ? Math.abs(values.precioVenta) : ""}
													onChange={(e) => {
														let inputValue = e.target.value.replace(/[^0-9.]/g, "");
														if (/^\d{0,3}(\.\d{0,3})?$/.test(inputValue)) {
															setFieldValue("precioVenta", inputValue === "" ? "" : -Math.abs(Number(inputValue)));
														}
													}}
													InputProps={{
														endAdornment: <InputAdornment position="end">%</InputAdornment>
													}}
													size="small"
													fullWidth
												/>
											)}

											<TextField
												type="hidden"
												name="precioVentaFinal"
												value={parseFloat(calcularPrecioVentaFinal(values).toString().replace(/[^0-9.]/g, "")) || ""}
												sx={{ display: "none" }}
											/>
										</Grid>

										{section === "projects" && (
											<>
												<Grid>
													<Typography sx={{ mt: '40px', ml: '15px', mr: '15px' }} fontWeight="bold">x</Typography>
												</Grid>

												<Grid item xs={12} sm={1.5}>
													<Typography sx={{ mb: '10px' }} fontWeight="bold">Unidades</Typography>
													<TextField
														type="number"
														label="Unidades"
														value={values.elementos > 0 ? values.elementos : ""}
														name="elementos"
														size="small"
														onChange={(e) => {
															let inputValue = e.target.value.replace(/[^0-9]/g, "");
															if (inputValue.length <= 3) {
																let newValue = inputValue === "" || inputValue === "0" ? 1 : Number(inputValue);
																setFieldValue("elementos", newValue);
															}
														}}
														onBlur={() => {
															if (!values.elementos || values.elementos <= 0) {
																setFieldValue("elementos", 1);
															}
														}}
														fullWidth
													/>
												</Grid>

												<Grid>
													<Typography sx={{ mt: '40px', ml: '15px', mr: '15px' }} fontWeight="bold">=</Typography>
												</Grid>
											</>
										)}

										<Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
											<Typography sx={{ mb: '10px' }} fontWeight="bold">
												Precio de Venta Proy.
											</Typography>
											<Typography fontSize={18} fontWeight="bold" sx={{ color: "purple", textAlign: "center" }}>
												{calcularPrecioVentaFinal(values)}
											</Typography>
										</Grid>

										<Grid item xs={12} sx={{ justifyContent: "center" }}>
											<Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
												<Typography fontWeight="bold">Seleccione el tipo de Precio de Venta</Typography>
											</Box>

											<Box sx={{ display: "flex", justifyContent: "center" }}>
												<RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} row>
													{section === "projects" && (
														<FormControlLabel
															value="real"
															control={<Radio />}
															onFocus={() => setFieldValue("precioVenta", 0)}
															label={`$ Venta LP + ${(projData.porcentajeUtilidad)}% U.P.`}
														/>
													)}
													<FormControlLabel
														value="personalizado"
														control={<Radio />}
														onFocus={() => { setFieldValue("precioVenta", Number(0)) }}
														label="$ Precio Particular"
													/>
													{section === "projects" ? (
														<>
															{/* 
														{projData.porcentajeUtilidad !== 0 && (
															<FormControlLabel
																value="automatico"
																control={<Radio />}
																onFocus={() => { setFieldValue("precioVenta", Number(0)) }}
																label={`${projData.porcentajeUtilidad}% Utilidad del Proyecto`}
															/>
														)} 
													*/}
														</>
													) : (
														<FormControlLabel
															value="automatico"
															control={<Radio />}
															onFocus={() => { setFieldValue("precioVenta", Number(0)) }}
															label={`${porcentajeGlobal}% Utilidad Global`}
														/>
													)}
													<FormControlLabel value="porcentajeUtilidad" control={<Radio />} onFocus={() => { setFieldValue("precioVenta", Number(0)) }} label="% Utilidad Particular" />
												</RadioGroup>
											</Box>

											<Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
												<Button
													type="submit"
													variant="contained"
													color={section === "projects" ? "success" : "primary"}
													disabled={calcularPrecioVentaFinal(values) === formatCurrency(0)}
													sx={{
														fontSize: "16px",
														padding: "10px 25px",
														textTransform: "none",
														borderRadius: "8px",
													}}
												>
													{section === "projects" ? "Actualizar Precio de Venta" : data.titleBtn2}
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Grid>
							</form>
						)}
					</Formik>
				</Box>
			</Grid>
		</Box>
	);
};
