import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore, Save, Cancel } from '@mui/icons-material';

import { Grid, Box, useTheme, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Pagination, Paper, Collapse, IconButton, Button, Modal, Switch, Typography, TextField } from '@mui/material';

import { useSnackbar } from 'notistack';
import { DataGrid } from "@mui/x-data-grid";

import { tokens } from "../../data/tokens";
import { Header } from "../basics/Header";

import { UseAppConfig } from '../../system/config';
import { sendPostData, getPostData, getDataArray } from '../../system/getData';
import { GetCompoundAmounts } from "../compounds/compoundList";

import ConfirmationDialog from "../globals/confirmation";
import SearchFilters from "../globals/searchFilters";
import calculatePrecioVenta from "../globals/sellPrice";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faCopy, faPlus, faSave, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { FormRcd } from "../catalogs/addCatalogs";
import { IconsDefault } from "../globals/globalForms";

import { CreateSection, CreateArticle, EditSection, ViewArticle, AddArticles, CreateUser, EditUser } from "../../components/projects/addElements";

import '../../theme/project.css';

const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
};

const handleResetForm = () => {
    localStorage.removeItem("searchFilters");
};

const formatFechaEdicion = (fechaString) => {
    const fecha = new Date(fechaString);
    const opcionesFecha = { year: 'numeric', month: '2-digit', day: '2-digit' };
    const opcionesHora = { hour: '2-digit', minute: '2-digit' };
    const fechaFormateada = fecha.toLocaleDateString('es-MX', opcionesFecha);
    const horaFormateada = fecha.toLocaleTimeString('es-MX', opcionesHora);

    return `${fechaFormateada} ${horaFormateada}`;
};

export const JSONDataTable = ({ data }) => {
    const navigate = useNavigate();
    const handleEdit = (field) => {
        navigate(`/${data.module}/edit/${field}`);
    };
    return (
        <Box m="20px">
            <Header title={data.title} subtitle={data.description} />
            <Paper elevation={3} className="panel">
                <Box mt={4}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {data.tableHeader.map((item, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{ backgroundColor: '#052c65', color: 'white' }}
                                        >
                                            {item.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.tableContent.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell
                                            sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                        >
                                            {item.name}
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                        >
                                            {item.description || 'Sin descripción'}
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={{ backgroundColor: index % 2 === 0 ? '#f1f2f3' : '#ffffff' }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                size="small"
                                                sx={{ mr: 1 }}
                                                onClick={() => handleEdit(item.field)}
                                            >
                                                Editar
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
        </Box>
    );
};

export const SimpleDataTable = ({ data }) => {
    const { config, configData } = UseAppConfig();
    const [dataTable, setDataTable] = useState([]);

    const [RcdID, setRcdID] = useState(0);
    const [RcdName, setRcdName] = useState('');

    const [modalSection, setModalSection] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [OpenConfirm, setConfirmOpen] = useState(false);
    const [ConfirmValue, setConfirmValue] = useState('');

    const { enqueueSnackbar } = useSnackbar();

    const fetchDataTable = async (params = {}) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}`, { params });

            let formattedData;
            const formatNumber = data.tableHeader.some(rcd => rcd.currency == 1);

            formattedData = jsonData.map(row => ({
                ...row,
                statusValue: row.status,
                status: row.status === 1 ? 'Activado' : 'Desactivado',
                total: formatCurrency(row.total),
                fechaCreacion: formatFechaEdicion(row.fechaCreacion),
                fechaEdicion: formatFechaEdicion(row.fechaEdicion)
            }));

            setDataTable(formattedData);
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
    };

    useEffect(() => {
        const savedFilters = localStorage.getItem('searchFilters');
        if (savedFilters) {
            const parsedFilters = JSON.parse(savedFilters);
            fetchDataTable(parsedFilters);
        } else {
            fetchDataTable();
        }
    }, [data.endpoint]);

    const handleConfirm = async () => {
        try {
            const delUsrID = await getPostData(`${config.API_BASE_URL}/elimina/usuario`, { id: RcdID });
            reloadData();
            handleConfirmClose();
        } catch (error) {
            console.error('Se ha generado un error a la hora de intentar obtener los datos:', error);
        }
        console.log('Confirmado');
    };

    const reloadData = async () => {
        try {
            const savedFilters = localStorage.getItem('searchFilters');
            if (savedFilters) {
                const parsedFilters = JSON.parse(savedFilters);
                fetchDataTable(parsedFilters);
            } else {
                fetchDataTable();
            }
        } catch (error) {
            enqueueSnackbar('Error al recargar los datos', { variant: 'error' });
        }
    };

    const handleModalOpen = (section, rcdID) => {
        setRcdID(rcdID);
        setModalOpen(true);
        setModalSection(section);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleConfirmsOpen = (params) => {
        setRcdID(params.id);
        setRcdName(params.RcdName);
        setConfirmValue(params.ConfirmValue);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'addUser':
                return <CreateUser reloadData={reloadData} setModalOpen={setModalOpen} />;
            case 'editUser':
                return <EditUser userID={RcdID} reloadData={reloadData} setModalOpen={setModalOpen} />;
            default:
                return null;
        }
    };

    return (
        <Box m="20px">
            <Header title={data.title} subtitle={data.description} />
            <Paper elevation={3} className="panel">
                <Box mt={4}>
                    {data.module === 'users' && (
                        <Box
                            key="Agregar Nuevo"
                            style={{ position: 'absolute', top: '50px', right: '60px', zIndex: 100 }}
                        >
                            <Button
                                style={{ marginLeft: '10px' }}
                                variant="contained"
                                color="info"
                                onClick={() => handleModalOpen('addUser')}
                            >
                                Agregar Nuevo Usuario
                            </Button>
                        </Box>
                    )}
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {data.tableHeader.map((header, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                backgroundColor: '#052c65',
                                                color: 'white',
                                                textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                flex: header.flex || 1,
                                            }}
                                        >
                                            {header.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataTable.map((row, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {data.tableHeader.map((header, cellIndex) => (
                                            <TableCell
                                                key={cellIndex}
                                                sx={{
                                                    backgroundColor: rowIndex % 2 === 0 ? '#f1f2f3' : '#ffffff',
                                                    textAlign: header.field === 'total' ? 'right' : (header.align || 'center'),
                                                    flex: header.flex || 1,
                                                    cursor: data.module === 'projects' ? 'pointer' : (header.align || 'normal'),
                                                }}
                                                onClick={
                                                    data.module === 'projects'
                                                        ? () => {
                                                            const targetUrl = `${data.module}/details/${row.id}`;
                                                            window.location.href = targetUrl;
                                                        }
                                                        : undefined
                                                }
                                            >
                                                {header.field === 'acciones' ? (
                                                    Object.keys(header.botons).map((buttonKey) => {
                                                        const button = header.botons[buttonKey];
                                                        return (
                                                            <Button
                                                                key={buttonKey}
                                                                variant="contained"
                                                                color={button.class}
                                                                size="small"
                                                                onClick={() => {
                                                                    if (button.label === 'Editar') {
                                                                        handleModalOpen('editUser', row.id);
                                                                    } else if (button.label === 'Eliminar') {
                                                                        handleConfirmsOpen({
                                                                            id: row.id,
                                                                            ConfirmValue: 6,
                                                                            RcdName: row.nombreArt
                                                                        });
                                                                    }
                                                                }}
                                                                style={{
                                                                    minWidth: '25px',
                                                                    height: '22px',
                                                                    margin: '1px',
                                                                    borderRadius: '0px',
                                                                    padding: '0px',
                                                                }}
                                                            >
                                                                {button.label === 'Editar' && <FontAwesomeIcon icon={faEdit} />}
                                                                {button.label === 'Eliminar' && <FontAwesomeIcon icon={faTrash} />}
                                                            </Button>
                                                        );
                                                    })
                                                ) : (
                                                    row[header.field]
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Paper>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '900px',
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}
                >
                    {renderModalContent()}
                </Paper>
            </Modal>
            <ConfirmationDialog
                open={OpenConfirm}
                onClose={handleConfirmClose}
                onConfirm={handleConfirm}
                statusValue={ConfirmValue}
                keyValue={RcdName}
            />
        </Box>
    );
};

export const DataTablePerID = ({ data, section }) => {
    const { id } = useParams();
    const { config } = UseAppConfig();
    const [dataTable, setDataTable] = useState({});

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSectionName, setModalSectionName] = useState('');
    const [modalSectionValue, setModalSectionValue] = useState('');
    const [modalSection, setModalSection] = useState(false);

    const handleModalOpen = (catalogValue, catalogName, section) => {
        setModalSectionValue(catalogValue);
        setModalSectionName(catalogName);
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const getColumns = (catalog) => {
        if (!catalog || !catalog.columns) return [];
        const columns = [
            ...(catalog.columns || []),
            {
                field: 'edit',
                headerName: 'Editar',
                width: 100,
                headerAlign: 'center',
                align: 'center',
                renderCell: (params) => (
                    <Box display="flex" justifyContent="center" width="100%">
                        <Button
                            style={{ width: '10px', minWidth: '10px' }}
                            variant="contained"
                            color="warning"
                            onClick={() => handleModalOpen(id, params.row.id, 'edit')}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Box>
                ),
            },
        ];
        return columns;
    };

    const fetchDataTable = async (field) => {
        try {
            const jsonData = await getPostData(`${config.API_BASE_URL}/${data.endpoint}/${field}`, {});

            let formattedData = jsonData.map(row => ({
                ...row,
                status: row.status === 1 ? 'Activo' : 'Desactivado',
                module: row.tipo === 'Simple' ? 'articles' : 'compounds'
            }));

            setDataTable(prevState => ({ ...prevState, [field]: formattedData }));
        } catch (error) {
            console.error('Error al obtener los datos:', error);
            setError(`No ha sido posible obtener los datos del registro: ${field}`);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (id) {
                setIsLoading(true);
                await fetchDataTable(id);
                setIsLoading(false);
            }
        };
        fetchData();
    }, [id]);

    if (isLoading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Box m="0px">
            <Box
                maxWidth="100%"
                mx="auto"
                mt="-25px"
                sx={{
                    "& .MuiTable-root": { border: "none" },
                    "& .MuiTableCell-root": { borderBottom: "none" },
                    "& .MuiTableHead-root": { backgroundColor: colors.primary[500], borderBottom: "none" },
                    "& .MuiTableCell-head": { color: colors.grey[900] },
                    "& .MuiTableContainer-root": { backgroundColor: colors.primary[900] },
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Registros del Catálogo</Typography>
                    <Button
                        variant="contained"
                        color="info"
                        onClick={() => handleModalOpen(id, section.name, 'new')}
                    >
                        <FontAwesomeIcon icon={faPlus} /> Agregar
                    </Button>
                </Box>
                <DataGrid
                    rows={dataTable[id] || []}
                    columns={getColumns(section) || []}
                    getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0 ? 'fila-par' : 'fila-impar'
                    }
                    disableColumnMenu
                    disableColumnSelector
                    hideFooter
                    className="datos-tabla"
                    autoHeight
                    sx={{
                        '& .MuiDataGrid-columnHeaders': {
                            backgroundColor: '#052c65',
                            color: '#fff',
                        }
                    }}
                />
            </Box>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: '100%',
                        maxWidth: '700px',
                        margin: '0 auto',
                        padding: 2,
                    }}
                >
                    {modalSection === 'new' ? (
                        <FormRcd secValue={modalSectionValue} secName={modalSectionName} />
                    ) : (
                        <FormRcd secValue={modalSectionValue} regID={modalSectionName} />
                    )}
                </Paper>
            </Modal>
        </Box>
    );
};

export const ExpandableDataTable = ({ data, projData }) => {
    const tableContainerRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();

    const [expandedRows, setExpandedRows] = useState(new Set());
    const [dataTable, setDataTable] = useState([]);
    const [loading, setLoading] = useState(true);

    const { config, configData } = UseAppConfig();
    const [totalProjectCost, setTotalProjectCost] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const [OpenConfirm, setConfirmOpen] = useState(false);
    const [ConfirmRcdID, setConfirmRcdID] = useState('');
    const [ConfirmValue, setConfirmValue] = useState('');

    const [RcdName, setRcdName] = useState('');

    const [page, setPage] = useState(() => {
        const savedPage = localStorage.getItem('currentPage');
        return savedPage ? parseInt(savedPage, 10) : 0;
    });

    const [rowsPerPage, setRowsPerPage] = useState(10);
    const totalPages = Math.ceil(dataTable.length / rowsPerPage);

    const porcentajeGlobal = configData ? configData.porcentajeGlobal : 0;

    useEffect(() => {
        localStorage.setItem('currentPage', page.toString());
    }, [page]);

    function calculateTotals(node, priceField = "precioVenta", finalPriceField = "precioVentaFinal", purchasePriceField = "precioCompra") {
        let totalVenta = 0;
        let totalCompra = 0;

        if (node.art && node.art.length > 0) {
            node.art.forEach(art => {
                const finalPrice = parseFloat(art[finalPriceField]?.replace(',', '')) || 0;
                const basePrice = parseFloat(art[priceField]?.replace(',', '')) || 0;
                const purchasePrice = parseFloat(art[purchasePriceField]?.replace(',', '')) || 0;

                totalCompra += purchasePrice;
                totalVenta += finalPrice > 0 ? finalPrice : basePrice;
            });
        }

        if (node.cat && node.cat.length > 0) {
            node.cat.forEach(subCategory => {
                totalVenta += calculateTotals(subCategory, priceField, finalPriceField, purchasePriceField);
                totalCompra += subCategory[purchasePriceField] || 0;
            });
        }

        node[priceField] = totalVenta;
        node[purchasePriceField] = totalCompra;

        return totalVenta;
    }

    const buildHierarchy = (flatData) => {
        const dataMap = {};
        const result = [];

        if (data.module === 'projects') {
            flatData.forEach(item => {
                const newPrice = parseFloat(item.precioVenta.replace(',', '')) || 0;
                dataMap[item.id] = { ...item, cat: [], art: item.art || [], precioVenta: newPrice };
            });

            flatData.forEach(item => {
                if (item.parent_id === null || item.parent_id === 'null') {
                    result.push(dataMap[item.id]);
                } else if (dataMap[item.parent_id]) {
                    dataMap[item.parent_id].cat.push(dataMap[item.id]);
                } else {
                    console.warn(`Nodo con ID ${item.id} tiene un parent_id (${item.parent_id}) no válido.`);
                }
            });

            result.forEach(rootNode => calculateTotals(rootNode));
        } else {
            flatData.forEach(item => {
                dataMap[item.id] = { ...item, cat: [], art: item.art || [] };
            });

            flatData.forEach(item => {
                if (item.parent_id === null || item.parent_id === 'null') {
                    result.push(dataMap[item.id]);
                } else if (dataMap[item.parent_id]) {
                    dataMap[item.parent_id].art.push(dataMap[item.id]);
                } else {
                    console.warn(`Nodo con ID ${item.id} tiene un parent_id (${item.parent_id}) no válido.`);
                }
            });
        }
        return result;
    };

    const fetchDataTable = async (params = {}) => {
        setLoading(true);
        console.log('Los Filtros de búsqueda son: ' + JSON.stringify(params));
        try {
            let formattedData = '';
            const response = await getDataArray(`${config.API_BASE_URL}/${data.contentList}`, { proyID: data.id, params });

            formattedData = response.map(row => ({
                ...row,
                precioVentaOriginal: row.precioVentaOriginal,
                rcdCount: row.elementos
            }));

            if (data.module === "projects") {
                const result = [];
                result.forEach(rootNode => calculateTotals(rootNode));
            } else {
                formattedData = formattedData.map(row => {
                    const { precioVentaValor, isPrecioVentaMenor } = calculatePrecioVenta(
                        row.precio_compra,
                        row.precio_venta,
                        porcentajeGlobal
                    );

                    return {
                        ...row,
                        precio_compra: row.precio_compra,
                        precio_venta: precioVentaValor,
                        isPrecioVentaMenor
                    };
                });
            }

            const hierarchicalData = buildHierarchy(formattedData);
            setDataTable(hierarchicalData);

            if (data.module === "projects") {
                const total = hierarchicalData.reduce((sum, category) => sum + category.precioVenta, 0);
                setTotalProjectCost(total);
                actualizaPrecioProyecto(total);
            }
        } catch (error) {
            console.error('Error fetching categories:', error);
            enqueueSnackbar('Error al cargar la información de los artículos', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const reloadData = async () => {
        try {
            const savedFilters = localStorage.getItem('searchFilters');
            if (savedFilters) {
                const parsedFilters = JSON.parse(savedFilters);
                fetchDataTable(parsedFilters);
            } else {
                fetchDataTable();
            }
        } catch (error) {
            enqueueSnackbar('Error al recargar los datos', { variant: 'error' });
        }
    };

    const actualizaPrecioProyecto = async (newPrice) => {
        try {
            try {
                const totalProyecto = await sendPostData(`${config.API_BASE_URL}/actualiza/precioProyecto`, { proyID: data.id, newPrice });
            } catch (error) {
                console.error('Error al consultar el nuevo Precio de Venta:', error);
            }
        } catch (error) {
            console.error('Error al actualizar la cantidad de este artículo:', error);
        }
    };

    const toggleRowExpansion = (id) => {
        setExpandedRows((prev) => {
            const newExpandedRows = new Set(prev);
            if (newExpandedRows.has(id)) {
                newExpandedRows.delete(id);
            } else {
                newExpandedRows.add(id);
            }
            return newExpandedRows;
        });
    };

    useEffect(() => {
        if (data.module === "articles") {
            const savedFilters = localStorage.getItem('searchFilters');
            if (savedFilters) {
                const parsedFilters = JSON.parse(savedFilters);
                fetchDataTable(parsedFilters);
            }
        } else {
            fetchDataTable();
        }
    }, [data.id]);

    const handleSearchSubmit = (values) => {
		localStorage.removeItem("currentPage");
		localStorage.removeItem("expandedRows");
        fetchDataTable(values);
    };

    const handleRcdEdit = async (thisType, thisID, thisName) => {
        switch (data.module) {
            case 'articles':
                break;
            case 'projects':
                switch (thisType) {
                    case 'updatePrices':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 8, RcdName: thisName });
                        break;
                    default:
                        console.warn('Tipo no reconocido:', thisType);
                        break;
                }
                break;
        }
    };

    const handleConfirmsOpen = (params) => {
        setConfirmRcdID(params.id);
        setRcdName(params.RcdName);
        setConfirmValue(params.ConfirmValue);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleConfirm = async () => {
        try {
            switch (data.module) {
                case 'articles':
                    break;
                case 'projects':
                    switch (ConfirmValue) {
                        case 8:
                            var url = `${config.API_BASE_URL}/actualiza/registrosPorProyecto`;
                            var params = { proyID: ConfirmRcdID };
                            break;
                    }
                    enqueueSnackbar('Precios actualizados exitosamente', { variant: 'success' });
                    break;
            }
            const result = await sendPostData(url, params);
            setConfirmOpen(false);
            console.log('Preparando para Refrescar');
            reloadData();
        } catch (error) {
            enqueueSnackbar('Error al intentar actualizar los precios', { variant: 'error' });
            setConfirmOpen(false);
        }
        console.log('Confirmado');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleModalOpen = (section) => {
        setModalSection(section);
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'addCategory':
                return <CreateSection proyID={data.id} reloadData={reloadData} setModalOpen={setModalOpen} />;
            case 'createArticle':
                return <CreateArticle reloadData={reloadData} setModalOpen={setModalOpen} />;
            default:
                return null;
        }
    };

    return (
        <>
            <Box mt={4}>
                {data.searchParams?.length > 0 ? (
                    <SearchFilters data={data.searchParams} onSubmit={handleSearchSubmit} />
                ) : (
                    null
                )}
                <Box>
                    {data.module === 'projects' ? (
                        <>
                            <Box
                                sx={{
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '-110px',
                                }}
                            >
                                <Typography variant="h5" gutterBottom>
                                    Precio de Venta Total: <strong>{formatCurrency(totalProjectCost)}</strong>
                                </Typography>
                            </Box>
                            <Box sx={{ mt: '20px' }} display="flex" alignItems="center" justifyContent="flex-end" gap={2}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => handleRcdEdit('updatePrices', data.id)}
                                >
                                    Actualizar Precios
                                </Button>
                                <Button
                                    variant="contained"
                                    color="info"
                                    onClick={() => handleModalOpen('addCategory')}
                                >
                                    Crear Nueva Categoría
                                </Button>
                            </Box>

                            <Box sx={{ mt: '40px' }} display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h4" gutterBottom>
                                    Categorías incluidas en el Proyecto:
                                </Typography>
                            </Box>
                        </>
                    ) : data.module === 'articles' ? (
                        <>
                            <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={() => handleModalOpen('createArticle')}
                                >
                                    Cargar Artículos
                                </Button>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="h4" gutterBottom>
                                    Seleccione la opción que desee para editar la información de cualquier artículo:
                                </Typography>
                            </Box>
                        </>
                    ) : null}
                </Box>
                <Box mt={4}>
                    <TableContainer ref={tableContainerRef} component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow sx={{ height: '0px' }}>
                                    {data.tableHeader.map((header, index) => (
                                        <TableCell
                                            key={index}
                                            sx={{
                                                backgroundColor: "#052c65",
                                                color: "white",
                                                width: header.width,
                                                height: '50px',
                                                textAlign: header.field === "total" ? "right" : header.align || "center",
                                            }}
                                        >
                                            {header.headerName}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataTable.length > 0 ? (
                                    dataTable
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, index) => (
                                            <ExpandableData
                                                key={index}
                                                data={row}
                                                projData={projData}
                                                porcentajeGlobal={porcentajeGlobal}
                                                configData={data}
                                                level={0}
                                                reloadData={reloadData}
                                                expandedRows={expandedRows}
                                                toggleRowExpansion={toggleRowExpansion}
                                            />
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={data.tableHeader ? data.tableHeader.length : 1} align="center">
                                            No hay datos para mostrar.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", width: "100%", mt: 2 }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
                            component="div"
                            count={dataTable.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelRowsPerPage="Registros por página:"
                        />

                        <Pagination
                            count={totalPages}
                            page={page + 1}
                            onChange={(event, value) => handleChangePage(event, value - 1)}
                            color="primary"
                            showFirstButton
                            showLastButton
                            siblingCount={2}
                            sx={{ mt: 2 }}
                        />
                    </Box>
                </Box>
                <Modal
                    open={modalOpen}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Paper
                        elevation={3}
                        sx={{
                            width: data.module === 'projects' ? '600px' : data.module === 'articles' ? '900px' : '50%',
                            padding: 3,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2
                        }}
                    >
                        {renderModalContent()}
                    </Paper>
                </Modal>
                <ConfirmationDialog
                    open={OpenConfirm}
                    onClose={handleConfirmClose}
                    onConfirm={handleConfirm}
                    statusValue={ConfirmValue}
                    keyValue={RcdName}
                />
            </Box>
        </>
    );
};

const ExpandableData = ({ data, projData, configData, porcentajeGlobal, level = 0, reloadData, expandedRows, toggleRowExpansion }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    const { config } = UseAppConfig();

    const [isAddingSubcategory, setIsAddingSubcategory] = useState(false);
    const [newSubcategoryName, setNewSubcategoryName] = useState('');

    const [artID, setArtID] = useState(false);
    const [artTipoID, setArtTipoID] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalSection, setModalSection] = useState(false);

    const [OpenConfirm, setConfirmOpen] = useState(false);
    const [ConfirmRcdID, setConfirmRcdID] = useState('');
    const [ConfirmValue, setConfirmValue] = useState('');

    const [RcdName, setRcdName] = useState('');
    const [SkuName, setSkuName] = useState('');

    const [isExpanded, setIsExpanded] = useState(false);

    const [localData, setLocalData] = useState(false);

    const [elementsValue, setElementsValue] = useState(data.elementos || '');
    const [amountValue, setAmountValue] = useState(data.cantidad || '');

    const textFieldRef = useRef(null);
    const [isDisabled, setIsDisabled] = useState(true);

    const [savedData, setSavedData] = useState(false);
    const savedDataRef = useRef(savedData);

    const [elementRcdCount, setElementRcdCount] = useState(0);
    const [amountRcdCount, setAmountRcdCount] = useState(0);

    const [elementPrecioVentaFinal, setElementPrecioVentaFinal] = useState(0);

    const commonCellStyle = {
        textAlign: 'center',
        width: '10%',
    };

    // Al montar el componente, recuperamos el estado de expansión de localStorage
    useEffect(() => {
        const storedExpandedRows = localStorage.getItem('expandedRows');
        if (storedExpandedRows) {
            const expandedIds = JSON.parse(storedExpandedRows);
            if (expandedIds.includes(data.id)) {
                setIsExpanded(true);
            }
        }
    }, [data.id]);

    const handleToggle = () => {
        const newExpanded = !isExpanded;
        setIsExpanded(newExpanded);

        // Recuperamos el array de filas expandidas o creamos uno nuevo
        const storedExpandedRows = localStorage.getItem('expandedRows');
        let expandedIds = storedExpandedRows ? JSON.parse(storedExpandedRows) : [];

        if (newExpanded) {
            // Agregamos el id si no está ya en el array
            if (!expandedIds.includes(data.id)) {
                expandedIds.push(data.id);
            }
        } else {
            // Lo eliminamos del array
            expandedIds = expandedIds.filter(id => id !== data.id);
        }
        localStorage.setItem('expandedRows', JSON.stringify(expandedIds));
    };

    const [sectionFormValues, setSectionFormValues] = useState({
        proyID: configData.id,
        secTitle: newSubcategoryName,
        secDesc: '',
        categoria_padre_id: data.id
    });

    const ColorsDefault = ({ level, id, nombre, cantidad, tipo }) => {
        switch (level) {
            case 1:
                setLocalData(true);
                break;
            default:
                setLocalData(false);
                break;
        }

        const styleConfig = {
            'Categoría': { color: 'black', fontWeight: level === 0 ? 'bold' : level === 1 ? 'normal' : 'normal', textTransform: 'uppercase' },
            'Compuesto': { color: 'green', fontWeight: 'normal', cursor: 'pointer' },
            'Simple': { color: 'blue', fontWeight: 'normal', cursor: 'pointer' },
        };

        const defaultStyle = { color: 'red', fontWeight: 'normal' };
        const appliedStyle = styleConfig[tipo] || defaultStyle;

        return (
            <span
                style={appliedStyle}
                onClick={tipo !== 'Categoría' ? () => handleRcdEdit('viewArt', id, data.nombre, data.tipo) : undefined}
            >
                {nombre} <span style={{ fontStyle: "italic", fontSize: "0.8em" }}>({cantidad})</span>
            </span>
        );
    };

    const botonesConfig = {
        articles: {
            'Compuesto': [
                { name: 'ediComptArt', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'delCompArt', label: 'Eliminar', color: 'error', icon: faTrash, imagen: '' },
                { name: 'saveCount', label: 'Guardar', color: 'success', icon: faSave, imagen: '' },
            ],
            'Simple': [
                { name: 'saveCount', label: 'Guardar', color: 'success', icon: faSave, imagen: '' },
                { name: 'editSimpArt', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'delSimpArt', label: 'Eliminar', color: 'error', icon: faTrash, imagen: '' },
            ],
        },
        projects: {
            'Categoría': [
                { name: 'subCat', label: 'Agregar Sub-Categoría', color: 'success', icon: faBox, imagen: '' },
                { name: 'editCat', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'addArt', label: 'Agregar Artículos', color: 'info', icon: faCopy, imagen: '' },
                { name: 'delCat', label: 'Eliminar', color: 'error', icon: faTrash, imagen: '' },
            ],
            'Compuesto': [
                { name: 'saveCount', label: 'Guardar', color: 'success', icon: faSave, imagen: '' },
                { name: 'editArt', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'delArt', label: 'Eliminar Artículo Compuesto', color: 'error', icon: faTrash, imagen: '' },
            ],
            'Simple': [
                { name: 'saveCount', label: 'Guardar', color: 'success', icon: faSave, imagen: '' },
                { name: 'editArt', label: 'Editar', color: 'warning', icon: faEdit, imagen: '' },
                { name: 'delArt', label: 'Eliminar Artículo Simple', color: 'error', icon: faTrash, imagen: '' },
            ],
        },
    };

    const botones =
        configData?.module === 'projects'
            ? (
                data?.tipo === 'Categoría' ||
                    data?.tipo === 'Sub Categoría' ||
                    (data?.tipo !== 'Categoría' && data?.tipo !== 'Sub Categoría' && data?.parent_id === null)
                    ? botonesConfig.projects[data.tipo] || []
                    : []
            )
            : botonesConfig[configData?.module]?.[data?.tipo] || [];

    const handleRcdEdit = async (thisType, thisID, thisKey, thisName, thisArtTipoID) => {
        switch (configData.module) {
            case 'articles':
                switch (thisType) {
                    case 'ediComptArt':
                        navigate(`/compounds/details/${thisID}`);
                        break;
                    case 'saveCount':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 10, RcdKey: thisKey, RcdName: thisName });
                        break;
                    case 'editSimpArt':
                        navigate(`details/${thisID}`);
                        break;
                    case 'delSimpArt':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 4, RcdKey: thisKey, RcdName: thisName });
                        break;
                    case 'delCompArt':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 5, RcdKey: thisKey, RcdName: thisName });
                        break;
                    case 'viewArt':
                        setArtID(thisID);
                        setArtTipoID(thisArtTipoID);
                        setModalSection('viewArticle');
                        setModalOpen(true);
                        break;
                    default:
                        console.warn('Tipo no reconocido:', thisType);
                        break;
                }
                break;
            case 'projects':
                switch (thisType) {
                    case 'subCat':
                        setIsAddingSubcategory(true);
                        break;
                    case 'editCat':
                        setModalSection('editCategory');
                        setModalOpen(true);
                        break;
                    case 'addArt':
                        setModalSection('addArticles');
                        setModalOpen(true);
                        break;
                    case 'saveCount':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 10, RcdKey: thisKey, RcdName: thisName });
                        break;
                    case 'editArt':
                        setModalSection('editArticle');
                        setModalOpen(true);
                        break;
                    case 'delCat':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 2, RcdName: thisName });
                        break;
                    case 'delArt':
                        handleConfirmsOpen({ id: thisID, ConfirmValue: 3, RcdKey: thisKey, RcdName: thisName });
                        break;
                    case 'viewArt':
                        setArtID(thisID);
                        setArtTipoID(thisArtTipoID);
                        setModalSection('viewArticle');
                        setModalOpen(true);
                        break;
                    default:
                        console.warn('Tipo no reconocido:', thisType);
                        break;
                }
                break;
        }
    };

    const renderModalContent = () => {
        switch (modalSection) {
            case 'addCategory':
                return <CreateSection proyID={data.id} />;
            case 'editCategory':
                return <EditSection data={data.id} reloadData={reloadData} setModalOpen={setModalOpen} />;
            case 'viewArticle':
                return configData.module === "projects" ? (
                    <ViewArticle id={artID} catID={data.catID} precioVenta={formatCurrency(data.precioVenta)} section={configData.module} />
                ) : (
                    <ViewArticle id={artID} precioVenta={data.precio_venta} section={configData.module} />
                );
            case 'addArticles':
                handleResetForm();
                return <AddArticles proyID={configData.id} catID={data.id} catName={data.nombre} reloadData={reloadData} setModalOpen={setModalOpen} />;
            case 'editArticle':
                return (
                    <GetCompoundAmounts
                        section={configData.module}
                        data={configData}
                        precioReal={data.precioVentaOriginal}
                        precioActual={data.precioVentaFinal}
                        projData={projData}
                        reloadData={reloadData}
                        setModalOpen={setModalOpen}
                        tipo={data.tipo}
                        initValues={data}
                    />
                )
            default:
                return null;
        }
    };

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const handleConfirmsOpen = (params) => {
        setConfirmRcdID(params.id);
        setRcdName(params.RcdName);
        setSkuName(params.RcdKey);
        setConfirmValue(params.ConfirmValue);
        setConfirmOpen(true);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleSaveSubcategory = async () => {
        if (newSubcategoryName.trim()) {
            setIsAddingSubcategory(false);
            const updatedValues = {
                ...sectionFormValues,
                secTitle: newSubcategoryName,
            };
            await onAddSubcategory(updatedValues);
        }
    };

    const handleConfirm = async () => {
        try {
            switch (configData.module) {
                case 'articles':
                    console.log('El tipo es: ' + ConfirmValue);

                    switch (ConfirmValue) {
                        case 4:
                        case 5:
                            var url = `${config.API_BASE_URL}/elimina/articulo`;
                            var params = { id: ConfirmRcdID };
                            break;
                    }
                    enqueueSnackbar('Artículo retirado exitosamente', { variant: 'success' });
                    break;
                case 'projects':
                    switch (ConfirmValue) {
                        case 2:
                            var url = `${config.API_BASE_URL}/retira/categoriaProyecto`;
                            var params = { proyID: configData.id, catID: ConfirmRcdID };
                            break;
                        case 3:
                            var url = `${config.API_BASE_URL}/retira/articuloCategoria`;
                            var params = { id: ConfirmRcdID };
                            break;
                        case 10:
                            var url = `${config.API_BASE_URL}/actualiza/numeroElementos`;
                            var params = { id: ConfirmRcdID, rcdCount: elementsValue };
                            break;
                    }
                    enqueueSnackbar('Artículo retirado exitosamente', { variant: 'success' });
                    break;
            }
            const result = await sendPostData(url, params);
            setConfirmOpen(false);
            console.log('Preparando para Refrescar');
            reloadData();
        } catch (error) {
            enqueueSnackbar('Error al intentar retirar este artículo', { variant: 'error' });
            setConfirmOpen(false);
        }
        console.log('Confirmado');
    };

    const onAddSubcategory = async (values) => {
        try {
            const result = await sendPostData(`${config.API_BASE_URL}/crea/categoria`, values);
            enqueueSnackbar('Sección Creada Exitosamente', { variant: 'success' });
            reloadData();
        } catch (error) {
            console.error('Error al crear la subcategoría:', error);
            enqueueSnackbar('Ha ocurrido un error al intentar crear la nueva sección', { variant: 'error' });
        }
    };

    useEffect(() => {
        setSectionFormValues((prevValues) => ({
            ...prevValues,
            secTitle: newSubcategoryName,
        }));
    }, [newSubcategoryName]);

    const handleCancelAddSubcategory = () => {
        setIsAddingSubcategory(false);
        setNewSubcategoryName('');
    };

    const handleStatusToggle = async (id, currentStatus) => {
        try {
            const url = `${config.API_BASE_URL}/edita/estado`;
            const newStatus = currentStatus === 'Activado' ? 0 : 1;

            const params = { id, status: newStatus, section: 'articulo' };
            const result = await sendPostData(url, params);

            reloadData();
        } catch (error) {
            console.error('Error al cambiar estado:', error);
            enqueueSnackbar('Error al cambiar el estado', { variant: 'error' });
        }
    };

    let modalWidth;
    let modalHeight;

    switch (modalSection) {
        case 'viewArticle':
            switch (artTipoID) {
                case 'Compuesto':
                    modalWidth = '1300px';
                    modalHeight = '800px';
                    break;
                default:
                    modalWidth = '800px';
                    modalHeight = '500px';
                    break;
            }
            break;
        case 'editCategory':
            modalWidth = '600px';
            modalHeight = '330px';
            break;
        case 'addArticles':
            modalWidth = '950px';
            modalHeight = '1000px';
            break;
        case 'editArticle':
            modalWidth = '750px';
            modalHeight = '700px';
            break;
        default:
            modalWidth = '700px';
            modalHeight = '400px';
            break;
    }

    const handleUpdateElements = async (id, rcdCount, precioVentaFinal) => {
        try {
            setSavedData(true);
            await sendPostData(`${config.API_BASE_URL}/actualiza/numeroElementos`, { id, rcdCount, precioVentaFinal });
            enqueueSnackbar('Cantidad actualizada exitosamente', { variant: 'success' });
            setIsDisabled(true);
            setElementsValue(rcdCount);
            reloadData();
        } catch (error) {
            console.error('Error al actualizar la cantidad de este artículo:', error);
            enqueueSnackbar('Error al Actualizar la Cantidad de este Artículo', { variant: 'error' });
        }
    };

    const handleElementsClick = () => {
        setIsDisabled(false);
        setTimeout(() => {
            if (textFieldRef.current) {
                textFieldRef.current.focus();
            }
        }, 50);
    };

    const handleElementsChange = (event) => {
        const value = event.target.value;
        if (/^\d{0,3}$/.test(value)) {
            setElementsValue(value);

            const nuevoPrecioVenta = data.precioVenta * Number(value);
            data.precioVentaFinal = nuevoPrecioVenta;
            data.rcdCount = value;

            setElementRcdCount(value);
            setElementPrecioVentaFinal(nuevoPrecioVenta);
        }
    };

    useEffect(() => {
        savedDataRef.current = savedData;
    }, [savedData]);

    const handleElementsBlur = (id) => {
        setTimeout(() => {
            if (!savedDataRef.current) {
                data.rcdCount = data.elementos;
                setElementsValue(data.elementos);
                data.precioVentaFinal = data.precioVenta * data.elementos;
                setIsDisabled(true);
                setSavedData(false);
            }
        }, 500);
    };

    const handleArticlesClick = async (id, rcdCount) => {
        try {
            setSavedData(true);
            await sendPostData(`${config.API_BASE_URL}/actualiza/numeroCantidades`, { id, rcdCount });
            enqueueSnackbar('Cantidad actualizada exitosamente', { variant: 'success' });
            setIsDisabled(true);
            setAmountRcdCount(rcdCount);
            reloadData();
        } catch (error) {
            console.error('Error al actualizar la cantidad de este artículo:', error);
            enqueueSnackbar('Error al Actualizar la Cantidad de este Artículo', { variant: 'error' });
        }
    };

    const handleAmountClick = () => {
        setIsDisabled(false);
        setTimeout(() => {
            if (textFieldRef.current) {
                textFieldRef.current.focus();
            }
        }, 50);
    };

    const handleAmountChange = (event) => {
        const value = event.target.value;

        if (/^\d{0,3}$/.test(value)) {

            const { precioVentaValor, isPrecioVentaMenor } = calculatePrecioVenta(
                data.precio_compra,
                data.precioVentaOriginal,
                porcentajeGlobal
            );

            setAmountValue(value);
            setAmountRcdCount(value);
            const nuevoPrecioVenta = precioVentaValor * Number(value);
            data.precio_venta = nuevoPrecioVenta;
            data.rcdCount = value;
        }
    };

    const handleAmountBlur = (id) => {
        setTimeout(() => {
            if (!savedDataRef.current) {
                const { precioVentaValor, isPrecioVentaMenor } = calculatePrecioVenta(
                    data.precio_compra,
                    data.precioVentaOriginal,
                    porcentajeGlobal
                );

                const nuevoPrecioVenta = precioVentaValor * data.cantidad;
                data.precio_venta = nuevoPrecioVenta;
                data.rcdCount = data.cantidad;

                setAmountValue(data.cantidad);
                setIsDisabled(true);
                setSavedData(false);
            }
        }, 500);
    };

    return (
        <>
            {data && (
                <TableRow sx={{ height: '0px' }}>
                    <TableCell
                        sx={{
                            borderBottom: 'none',
                            position: 'relative',
                            backgroundColor: '#f5f5f5',
                            paddingLeft: `${level * 2}%`,
                            width: '20%'
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                                <Box component="span"
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '20px'
                                    }}>
                                    <IconsDefault tipo={data.tipo} icon={data.icono} />
                                    <span style={{ marginLeft: '10px', marginRight: '1px', fontSize: '20px' }}></span>
                                    <ColorsDefault
                                        level={level}
                                        id={configData.module === "projects" ? data.artID : data.id}
                                        nombre={data.nombreArt}
                                        cantidad={data.cantidad}
                                        tipo={data.tipo}
                                    />
                                </Box>
                            </Typography>
                            {(data.art && data.art.length > 0) || (data.cat && data.cat.length > 0) ? (
                                <IconButton
                                    sx={{
                                        marginLeft: '-6px',
                                        marginRight: -1,
                                        cursor: 'pointer'
                                    }}
                                    onClick={handleToggle}
                                    aria-label={isExpanded ? 'Colapsar' : 'Expandir'}
                                >
                                    {isExpanded ? <ExpandLess /> : <ExpandMore />}
                                </IconButton>
                            ) : (
                                <span
                                    style={{
                                        marginLeft: level === 0 ? '10px' : level === 1 ? '-10px' : '-5px',
                                        marginRight: '15px',
                                        fontSize: '20px'
                                    }}
                                >
                                    &nbsp;
                                </span>
                            )}
                        </Box>
                    </TableCell>

                    {Object.keys(data)
                        .filter((field) =>
                            !(configData.module === 'projects'
                                ? [
                                    'id',
                                    'artID',
                                    'catID',
                                    'padreID',
                                    'hijoID',
                                    'parent_id',
                                    'cantidad',
                                    'nombreArt',
                                    'familia',
                                    'tipo',
                                    'icono',
                                    'status',
                                    'cat',
                                    'art',
                                    'precio_compra',
                                    'precio_venta',
                                    'valorReferencia',
                                    'precioVentaFinal',
                                    'isPrecioVentaMenor',
                                    'precioVentaOriginal',
                                    'rcdCount'
                                ]
                                : [
                                    'id',
                                    'artID',
                                    'padreID',
                                    'hijoID',
                                    'parent_id',
                                    'nombre',
                                    'nombreArt',
                                    'familia',
                                    'tipo',
                                    'icono',
                                    'cat',
                                    'art',
                                    'isPrecioVentaMenor',
                                    'precioVentaOriginal',
                                    'rcdCount'
                                ]
                            ).includes(field)
                        )
                        .map((field, index) => (
                            <TableCell
                                key={`cell-${index}-${field}`}
                                sx={{
                                    ...commonCellStyle,
                                    textAlign: index === 1 ? 'left' : 'center',
                                }}
                                className={field === 'precio_venta' && data.isPrecioVentaMenor ? 'red-text' : ''}
                            >
                                {(() => {
                                    switch (field) {
                                        case 'familia':
                                            return (
                                                <div style={{
                                                    textAlign: 'center',
                                                    color: 'inherit',
                                                    fontStyle: 'normal'
                                                }}>
                                                    {data[field]}
                                                </div>
                                            );
                                        case 'elementos':
                                        case 'cantidad':
                                            return (
                                                <div style={{ textAlign: 'center', color: 'inherit', fontStyle: 'normal' }}>
                                                    <div
                                                        onClick={field === 'cantidad' ? handleAmountClick : handleElementsClick}
                                                        style={{
                                                            display: 'inline-block',
                                                            width: '65px',
                                                            height: '35px',
                                                            cursor: isDisabled ? 'pointer' : 'text',
                                                        }}
                                                    >
                                                        {localData ? (
                                                            <TextField
                                                                name="cantidad"
                                                                value={field === 'cantidad' ? amountValue : elementsValue}
                                                                onChange={field === 'cantidad' ? handleAmountChange : handleElementsChange}
                                                                onBlur={() => field === 'cantidad' ? handleAmountBlur(data['id']) : handleElementsBlur(data['id'])}
                                                                size="small"
                                                                variant="outlined"
                                                                type="number"
                                                                sx={{
                                                                    width: '65px',
                                                                    pointerEvents: isDisabled ? 'none' : 'auto',
                                                                }}
                                                                inputProps={{ step: 'any', min: 1, max: 999 }}
                                                                disabled={isDisabled}
                                                                inputRef={textFieldRef}
                                                            />
                                                        ) : (
                                                            <div>
                                                                {field === 'cantidad' ? data.cantidad : data.elementos}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            );
                                        case 'precioCompra':
                                        case 'precio_compra':
                                            return (
                                                <div style={{
                                                    textAlign: 'right',
                                                    color: 'inherit',
                                                    fontStyle: 'normal'
                                                }}>
                                                    {formatCurrency(data[field])}
                                                </div>
                                            );
                                        case 'precioVenta':
                                        case 'precio_venta':
                                            let fontStyle = '';
                                            let fontColor = '';
                                            let fontWeight = '';
                                            let isBelowCost = false;
                                            let precioVenta = data[field];

                                            const precioCompra = Number(data['precioCompra'] ?? data['precio_compra']);

                                            if (configData.module === "projects") {
                                                if (data['precioVentaFinal'] && Number(data['precioVentaFinal']) > 0) {
                                                    if (data['precioVentaFinal'] > data['precioCompra']) {
                                                        fontColor = 'blue';
                                                        fontStyle = 'italic';
                                                        fontWeight = 'bold';
                                                    } else if (data['precioVentaFinal'] == data['precioVenta']) {
                                                        fontColor = 'black';
                                                        fontStyle = 'normal';
                                                        fontWeight = 'normal';
                                                    } else if (data['precioVentaFinal'] < 0) {
                                                        fontColor = 'purple';
                                                        fontStyle = 'italic';
                                                        fontWeight = 'bold';
                                                    } else {
                                                        fontColor = 'black';
                                                        fontStyle = 'normal';
                                                        fontWeight = 'normal';
                                                    }
                                                    precioVenta = Number(data['precioVentaFinal']);
                                                } else {
                                                    fontColor = 'black';
                                                    fontStyle = 'normal';
                                                    fontWeight = 'normal';
                                                    precioVenta = Number(precioVenta);
                                                }
                                            } else {
                                                if (data['precioVentaOriginal'] <= 0) {
                                                    fontColor = 'black';
                                                    fontStyle = 'normal';
                                                    fontWeight = 'normal';
                                                } else if (data['precioVentaFinal'] < 0) {
                                                    fontColor = 'purple';
                                                    fontStyle = 'italic';
                                                    fontWeight = 'bold';
                                                } else {
                                                    fontColor = 'black';
                                                    fontStyle = 'normal';
                                                    fontWeight = 'normal';
                                                }
                                            }
                                            isBelowCost = precioVenta < precioCompra;

                                            return (
                                                <div style={{
                                                    textAlign: 'right',
                                                    color: isBelowCost ? 'red' : fontColor,
                                                    fontStyle: isBelowCost ? 'italic' : fontStyle,
                                                    fontWeight: fontWeight
                                                }}>
                                                    {formatCurrency(precioVenta)}
                                                </div>
                                            );
                                        case 'status':
                                            const shouldRenderSwitch =
                                                configData.module === "projects" ||
                                                (configData.module === "articles" && level == 0);

                                            if (shouldRenderSwitch) {
                                                return (
                                                    <Switch
                                                        checked={data[field] === 'Activado'}
                                                        onChange={() => handleStatusToggle(data.id, data[field])}
                                                        color="primary"
                                                    />
                                                );
                                            }
                                        default:
                                            return data[field];
                                    }
                                })()}
                            </TableCell>
                        ))}

                    <TableCell sx={{ textAlign: 'center', width: '10%' }}>
                        {botones
                            .filter((button) => {
                                if (configData.module === "projects") {
                                    return true;
                                }

                                if (configData.module === "articles") {
                                    if (level == 0) {
                                        switch (button.name) {
                                            case 'editSimpArt':
                                            case 'delSimpArt':
                                            case 'ediComptArt':
                                            case 'delCompArt':
                                                return true;
                                            default:
                                                return false;
                                        }
                                    } else if (level <= 1) {
                                        return button.name === 'saveCount';
                                    } else {
                                        return false;
                                    }
                                }
                            })

                            .map((button, index) => (
                                <Button
                                    key={index}
                                    variant="contained"
                                    color={button.color}
                                    size="small"
                                    title={button.label}
                                    onClick={() => {
                                        if (button.name === 'saveCount') {
                                            if (configData.module === 'articles') {
                                                handleArticlesClick(data.artID, data.rcdCount);
                                            } else {
                                                handleUpdateElements(data.id, elementRcdCount, elementPrecioVentaFinal);
                                            }
                                        } else {
                                            handleRcdEdit(button.name, data.id, data.clave, data.nombreArt);
                                        }
                                    }}
                                    disabled={
                                        (button.name === 'saveCount' && ((data.rcdCount === data.elementos) || isDisabled)) ||
                                        (button.name === 'editArt' && (data.rcdCount !== data.elementos))
                                    }
                                    style={{
                                        minWidth: '25px',
                                        height: '22px',
                                        margin: '1px',
                                        borderRadius: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    {button.imagen ? (
                                        <img
                                            src={`/assets/icons/${button.imagen}`}
                                            width="12px"
                                            alt={button.label}
                                            style={{ bottom: '-1px', position: 'relative' }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon icon={button.icon} style={{ fontSize: '12px' }} />
                                    )}
                                </Button>
                            ))}
                    </TableCell>
                </TableRow >
            )}

            {
                isAddingSubcategory && (
                    <TableRow sx={{ '& > *': { padding: '4px 8px', height: '0px' } }}>
                        <TableCell colSpan={10} sx={{ paddingLeft: `${(level + 1) * 2.5}%` }}>
                            <Typography>Agregar Nueva Sub Categoría:</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
                                <TextField
                                    size="small"
                                    label="Nombre de la Subcategoría"
                                    placeholder="Nombre de la subcategoría"
                                    variant="outlined"
                                    value={newSubcategoryName}
                                    onChange={(e) => setNewSubcategoryName(e.target.value)}
                                    sx={{ marginRight: 1, flexGrow: 1 }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleSaveSubcategory}
                                    startIcon={<Save />}
                                >
                                    Guardar
                                </Button>
                                <Button
                                    variant="contained"
                                    color="error"
                                    size="small"
                                    onClick={handleCancelAddSubcategory}
                                    startIcon={<Cancel />}
                                    sx={{ marginLeft: 1 }}
                                >
                                    Cancelar
                                </Button>
                            </Box>
                        </TableCell>
                    </TableRow>
                )
            }

            {/* Subniveles - Categorías */}
            {data.cat && data.cat.length > 0 && (
                <TableRow sx={{ height: '5px' }}>
                    <TableCell colSpan={10} sx={{ padding: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.cat.map((child, index) => (
                                        <ExpandableData
                                            key={index}
                                            data={child}
                                            projData={projData}
                                            configData={configData}
                                            porcentajeGlobal={porcentajeGlobal}
                                            level={level + 1}
                                            reloadData={reloadData}
                                            expandedRows={expandedRows}
                                            toggleRowExpansion={toggleRowExpansion}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            {/* Subniveles - Artículos */}
            {data.art && data.art.length > 0 && (
                <TableRow sx={{ height: '0px' }}>
                    <TableCell colSpan={10} sx={{ padding: 0 }}>
                        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                            <Table size="small">
                                <TableBody>
                                    {data.art.map((child, index) => (
                                        <ExpandableData
                                            key={index}
                                            data={child}
                                            projData={projData}
                                            configData={configData}
                                            porcentajeGlobal={porcentajeGlobal}
                                            level={level + 1}
                                            reloadData={reloadData}
                                            expandedRows={expandedRows}
                                            toggleRowExpansion={toggleRowExpansion}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}

            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Paper
                    elevation={3}
                    sx={{
                        width: modalWidth,
                        maxHeight: modalHeight,
                        overflowY: 'auto',
                        padding: 3,
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                    }}
                >
                    {renderModalContent()}
                </Paper>
            </Modal>
            <ConfirmationDialog
                open={OpenConfirm}
                onClose={handleConfirmClose}
                onConfirm={handleConfirm}
                statusValue={ConfirmValue}
                keyValue={RcdName}
                skuValue={SkuName}
            />
        </>
    );
}