import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const ConfirmationDialog = ({ open, onClose, onConfirm, statusValue, keyValue, skuValue }) => {
    let dialogTitle, dialogContent, btnColor, confirmButtonText, btnCancelColor, cancelBtnText;
    switch (statusValue) {
        case 0:
            dialogTitle = "Activar Artículo";
            dialogContent = "Está seguro de querer activar este artículo?";
            btnCancelColor = "error";
            cancelBtnText = "No";
            btnColor = "success";
            confirmButtonText = "Si";
            break;
        case 1:
            dialogTitle = "Desactivar Artículo";
            dialogContent = "Está seguro de querer desactivar este artículo?";
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "warning";
            confirmButtonText = "Si";
            break;
        case 2:
            dialogTitle = `Retirar Categoría ${keyValue}?`;
            dialogContent = (
                <>
                    Está seguro de querer retirar la categoría <strong>{keyValue}</strong> y todo su contenido?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "error";
            confirmButtonText = "Si";
            break;
        case 3:
            dialogTitle = `Retirar Artículo ${keyValue} (${skuValue})?`;
            dialogContent = (
                <>
                    <div style={{ textAlign: 'center' }}>
                        Está seguro de querer retirar el artículo: <br /><strong>{keyValue} ({skuValue})</strong><br /> Esta acción afectará directamente en el Precio de Venta del Proyecto
                    </div>
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "error";
            confirmButtonText = "Si";
            break;
        case 4:
            dialogTitle = `Eliminar Artículo Simple ${keyValue} (${skuValue})?`;
            dialogContent = (
                <>
                    <div style={{ textAlign: 'center' }}>
                        Está seguro de querer eliminar completamente el artículo: <br /><strong>{keyValue} ({skuValue})</strong>?
                    </div>
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "error";
            confirmButtonText = "Si";
            break;
        case 5:
            dialogTitle = `Eliminar Artículo Compuesto ${keyValue} (${skuValue})?`;
            dialogContent = (
                <>
                    <div style={{ textAlign: 'center' }}>
                        Está seguro de querer eliminar completamente este artículo compuesto y todo su contenido?
                    </div>
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "error";
            confirmButtonText = "Si";
            break;
        case 6:
            dialogTitle = `Eliminar Usuario ${keyValue}?`;
            dialogContent = (
                <>
                    Está seguro de querer eliminar este Usuario?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "error";
            confirmButtonText = "Si";
            break;
        case 7:
            dialogTitle = `Retirar el artículo ${keyValue} de este Compuesto?`;
            dialogContent = (
                <>
                    Está seguro de querer retirar este artículo del Compuesto?
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "error";
            confirmButtonText = "Si";
            break;
        case 8:
            dialogTitle = (
                <>
                    <div style={{ textAlign: 'center' }}><h5>Alerta de Actualización de Presupuesto</h5></div>
                </>
            );
            dialogContent = (
                <>
                    <div style={{ textAlign: 'center' }}>
                        Se detectaron cambios en el catálogo de artículos relacionados. Al aceptar esta actualización, el presupuesto se ajustará automáticamente en el sistema de precios unitarios.
                    </div>
                </>
            );
            btnCancelColor = "error";
            cancelBtnText = "Cancelar";
            btnColor = "success";
            confirmButtonText = "Aceptar";
            break;
        case 9:
            dialogTitle = (
                <>
                    <div style={{ textAlign: 'center' }}><h5>Se requiere Atención</h5></div>
                </>
            );
            dialogContent = (
                <>
                    <div style={{ textAlign: 'center' }}>Es necesario configurar el Factor de Utilidad del Proyecto para poder editar los Precios de Venta</div>
                </>
            );
            btnCancelColor = "success";
            cancelBtnText = "Aceptar";
            btnColor = "";
            confirmButtonText = "";
            break;
        case 10:
            dialogTitle = `Actualizar número de Elementos al Artículo ${keyValue}?`;
            dialogContent = (
                <>
                    <div style={{ textAlign: 'center' }}>Está seguro de querer Actualizar el número de Elementos del artículo <br />{keyValue} SKU: ({skuValue})</div>
                </>
            );
            btnCancelColor = "warning";
            cancelBtnText = "No";
            btnColor = "success";
            confirmButtonText = "Si";
            break;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogContent}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {cancelBtnText && (
                    <Button variant="contained" onClick={onClose} color={btnCancelColor} autoFocus>
                        {cancelBtnText}
                    </Button>
                )}
                {confirmButtonText && (
                    <Button variant="contained" onClick={onConfirm} color={btnColor} autoFocus>
                        {confirmButtonText}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;
