import { useState, useEffect } from 'react';
import { FormatoArticulosCompuestos } from "../../components/globals/globalForms";
import { editAmount } from "../../data/forms/compounds";
import { useParams } from "react-router-dom";

import * as yup from "yup";

import { UseAppConfig } from '../../system/config';
import { getPostData } from '../../system/getData';

const EditRcd = () => {
	// Parámetros de Configuración para cargar los datos requeridos
	// ----------------------------------------------------------------------------------------
		const dataConfig = {
			dbID: "id",
			title: "",
			key: editAmount,
			module: 'projects',
			description: "A continuación se detalla el Compuesto seleccionado así como todas sus dependencias",
			titleBtn: "Actualizar Cantidad",
			colorBtn: "secondary",
			colorBtn2: "warning",
			titleBtn2: "Actualizar Precio de Venta",
			msgSuccess: "Precio de Compra actualizado exitosamente",
			msgError: "Error al actualizar el Precio de Venta",
			getData: "datos/registroPorProyecto",
			sendData: "elementos/elementosPorProyecto"
		}

		const validateSchemas = yup.object().shape({
			precioCompra: yup.number().required('Requerido').typeError('Debe ser un número'),
		});
	// ----------------------------------------------------------------------------------------


	// No Tocar este bloque de código
	// ========================================================================================
		const { id } = useParams();
		const [ initValues, setInitValues ] = useState(null);
		const [ loading, setLoading ] = useState(true);

		const { config, configData } = UseAppConfig();

		useEffect(() => {
			const fetchData = async () => {
				const initVals = await getPostData(`${config.API_BASE_URL}/${dataConfig.getData}`, { id });
				setInitValues(initVals);
				setLoading(false);
			};
			fetchData();
		}, [id, dataConfig.getData]);

		if (loading) {
			return <div>Cargando...</div>;
		}

		return (
			<FormatoArticulosCompuestos
				data={dataConfig}
				initValues={initValues}
				validateSchemas={validateSchemas}
			/>
		);
	// ========================================================================================
}

export default EditRcd;